import { Button, Checkbox, Col, Icon, Radio, Row } from 'antd';
import React, { Component } from 'react';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import { required } from './FormValidator';

const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

class Staffs extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const radioStyle = {
			display: 'block',
			height: '30px',
			lineHeight: '30px'
		};
		const otherInputStyle = { width: 100, marginLeft: 10 };
		return (
			<div>
				<Form className="firstForm" ref={this.props.propsFromIndex.form}>
					<React.Fragment key="opdFrag">
						<Row>
							<h1 className="deptHeader">Human Resources</h1>
							<Col span={12}>
								{this.props.staffs.map((staff, index) => {
									return (
										<React.Fragment>
											<div className="staffIndex">

												<label>Name of the staff</label>
												<div className="position-relative">
													<Input
														validations={[required]}
														type="text"
														name="name"
														value={staff.name}
														onChange={this.props.updateStaffs.bind(this, index)}
													/>
												</div>


												<label>Qualification</label>
												<div className="position-relative">
													<Input
														validations={[required]}
														type="text"
														name="qualification"
														value={staff.qualification}
														onChange={this.props.updateStaffs.bind(this, index)}
													/>
												</div>

												<label>Registration Number</label>
												<div className="position-relative">
													<Input
														validations={[required]}
														type="text"
														name="regNumber"
														value={staff.regNumber}
														onChange={this.props.updateStaffs.bind(this, index)}
													/>
												</div>

												<label>Employment Nature</label>
												<div className="position-relative">
													<RadioGroup
														className="flexIt"
														name="employmentNature"
														onChange={this.props.updateStaffs.bind(this, index)}
														value={staff.employmentNature}
													>
														<RadioButton value="Permanent">Permanent</RadioButton>
														<RadioButton value="Temporary">Temporary</RadioButton>
													</RadioGroup>
													{(staff.employmentNature === "") && (this.props.propsFromIndex.isSubmit) &&
														<span className="form-error is-visible">Required</span>
													}
												</div>

												<label>Category of Staff</label>
												<div className="position-relative">
													<RadioGroup
														name="category"
														onChange={this.props.updateStaffs.bind(this, index)}
														value={staff.category}
													>
														<Col span={16}>
															<Radio style={radioStyle} value="Doctor">
																Doctor
														</Radio>
															<Radio style={radioStyle} value="Nursing Staff">
																Nursing Staff
														</Radio>
															<Radio style={radioStyle} value="Para-medical Staff">
																Para-medical Staff
														</Radio>
														</Col>
														<Col span={8}>
															<Radio style={radioStyle} value="Pharmacists">
																Pharmacists
														</Radio>
															<Radio style={radioStyle} value="Support Staff">
																Support Staff
														</Radio>
															<Radio style={radioStyle} value="other">
																Other
														</Radio>
														</Col>
													</RadioGroup>
													{(((staff.category === "")) && this.props.propsFromIndex.isSubmit) &&
														<span className="form-error is-visible">Required</span>
													}
												</div>

												<Checkbox
													name="isTechnical"
													checked={staff.isTechnical}
													onChange={this.props.updateStaffs.bind(this, index)}>Technical Staff?</Checkbox>

												<Checkbox
													name="canSignTestReport"
													checked={staff.canSignTestReport}
													onChange={this.props.updateStaffs.bind(this, index)}>Can sign test reports?</Checkbox>


												{this.props.staffs.length > 1 && (
													<Button
														type="dashed"
														onClick={this.props.delStaff.bind(this, index)}
														style={{ marginTop: 34 }}
													>
														<Icon type="minus" />
													</Button>
												)}
											</div>
										</React.Fragment>
									);
								})}
							</Col>
						</Row>
						<Row>
							<Col span={5}>
								<Button
									type="primary"
									ghost
									onClick={this.props.addStaff}
									style={{ marginTop: 10, width: '100%' }}
								>
									<Icon type="plus" /> Add another staff
								</Button>
							</Col>
						</Row>
					</React.Fragment>
				</Form>
			</div>
		);
	}
}
export default Staffs;
