import React, { Component } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import generatePdf from "./generatePdf";
import CeaRegistrationForm from "./CeaRegistrationForm";

class Main extends Component {
  render() {
    return (
      <main>
        <BrowserRouter>
          <Switch>
            <Route exact path="/generate-pdf" component={generatePdf} />
            <Route exact path="/" component={CeaRegistrationForm} />
          </Switch>
        </BrowserRouter>
      </main>
    );
  }
}

export default Main;
