import React, { Component } from "react";
import { Row, Col } from "antd";
import "./styles.css";

class APageOp extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    console.log(this.props);
  }

  render() {
    const {
      first,
      second,
      third,
      fourth,
      ipDepartment,
      opDepartment,
      systems
    } = this.props.state;
    let { annexureNo } = this.props;

    const generatePatientTable = (type, list) => {
      const numberLabel = type === "op" ? "Number of Rooms" : "Number of beds";
      return (
        <div className="question patient-table">
          <Row className="is-center text-left">
            <Col offset={2} span={7}>
              <p className="question text-center border-box mb-0">No.</p>
            </Col>
            <Col span={7}>
              <p className="question text-center border-box mb-0">Speciality</p>
            </Col>
            <Col span={6}>
              <p className="question text-center border-box mb-0">
                {numberLabel}
              </p>
            </Col>
          </Row>
          <Row className="is-center text-left">
            {list.map((item, index) => {
              return (
                <React.Fragment>
                  <Col offset={2} span={7}>
                    <p className="answer text-center border-box mb-0">
                      {index + 1}
                    </p>
                  </Col>
                  <Col span={7}>
                    <p className="answer text-center border-box mb-0">
                      {item.speciality}
                    </p>
                  </Col>
                  <Col span={6}>
                    <p className="answer text-center border-box mb-0">
                      {type === "op" ? item.numberOfRooms : item.numberOfBeds}
                    </p>
                  </Col>
                </React.Fragment>
              );
            })}
          </Row>
        </div>
      );
    };
    let totalBed = 0;
    ipDepartment.map(item => {
      console.log(item);
      totalBed = totalBed + Number(item.numberOfBeds);
    });
    return (
      <div className="APageOp">
        <div>
          <Row className="is-center text-left">
            <Col offset={2} span={20}>
              <p className="text-center heading">ANNEXURE {annexureNo}</p>
            </Col>
          </Row>
          <Row className="is-center text-left">
            <Col offset={2} span={22}>
              <p className="question ">In-Patient Department</p>
            </Col>
            <Col offset={3} span={10}>
              <p className="question">
                a. Total number of beds:{" "}
                <span className="answer">{totalBed}</span>
              </p>
            </Col>
            <Col span={10} pull={1}>
              <p className="question ">
                Speciality-wise distribution of beds, please specify:
              </p>
            </Col>
          </Row>
          {generatePatientTable("in", ipDepartment)}
        </div>
      </div>
    );
  }
}

export default APageOp;
