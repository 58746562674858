import { Col, Radio, Row } from 'antd';
import React, { Component } from 'react';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import { required } from './FormValidator';
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

class Second extends Component {
	constructor(props) {
		super(props);
		this.state = {
			location: '',
			otherLocation: ''
		};
	}

	onChange = e => {
		this.props.update(e.target.name, e.target.value);
	};
	onRadioChange = e => {
		this.props.update(e.target.name, e.target.value);
	};
	onRadioChange1 = e => {
		console.log(e.target.name, e.target.value);
		this.props.update(e.target.name, e.target.value);
		this.props.update('estOwnrPublic', '');
		this.props.update('estOwnrPublicOther', '');
		this.props.update('estOwnrPrivate', '');
		this.props.update('estOwnrPrivateOther', '');
		this.props.update('corporationSpec', '');
		this.props.update('trustSpec', '');
		this.props.update('foreignSpec', '');
		this.props.update('foreignSpec', '');
	};
	onOtherOptionChange = e => {
		this.props.update(e.target.name, e.target.value);
	};

	render() {
		const radioStyle = {
			display: 'block',
			height: '30px',
			lineHeight: '30px'
		};
		const otherInputStyle = { width: 150, marginLeft: 10 };
		return (
			<div>
				<Form ref={this.props.propsFromIndex.form} className="firstForm">
					<h1>Ownership of service</h1>
					<Row>
						<Col span={12}>
							<label>Type of sector</label>
							<RadioGroup
								className="flexIt"
								name="estOwnership"
								onChange={this.onRadioChange1}
								value={this.props.propsFromIndex.estOwnership}
							>
								<RadioButton style={radioStyle} value="private">
									Private Sector
								</RadioButton>
								<RadioButton style={radioStyle} value="public">
									Public Sector
								</RadioButton>
							</RadioGroup>
							{this.props.propsFromIndex.estOwnership.length === 0 &&
								this.props.propsFromIndex.isSubmit && (
									<span className="form-error is-visible">Required</span>
								)}
						</Col>
					</Row>
					<Row>
						{this.props.propsFromIndex.estOwnership === 'public' && (
							<Col span={12}>
								<Row>
									<label>Select a subtype</label>
								</Row>
								<RadioGroup
									name="estOwnrPublic"
									onChange={this.onRadioChange}
									value={this.props.propsFromIndex.estOwnrPublic}
								>
									<Radio style={radioStyle} value="capital">
										Central Government
									</Radio>
									<Radio style={radioStyle} value="state">
										State Government
									</Radio>
									<Radio style={radioStyle} value="local">
										Local Government
										{this.props.propsFromIndex.estOwnrPublic === 'local' ? (
											<Input
												placeholder="please specify"
												validations={[required]}
												name="estOwnrLocalOther"
												onChange={this.onOtherOptionChange}
												value={this.props.propsFromIndex.estOwnrLocalOther}
												style={otherInputStyle}
											/>
										) : null}
									</Radio>
									<Radio style={radioStyle} value="psu">
										Public Sector Undertaking
									</Radio>
									<Radio style={radioStyle} value="railways">
										Railways
									</Radio>
									<Radio style={radioStyle} value="esic">
										Employees State Insurance Corporation
									</Radio>
									<Radio style={radioStyle} value="autonomous">
										Autonomous Organization
									</Radio>
									<Radio style={radioStyle} value="society">
										Society/Not for profit company
									</Radio>
									<Radio style={radioStyle} value="other">
										Other
										{this.props.propsFromIndex.estOwnrPublic === 'other' ? (
											<input
												placeholder="please specify"
												name="estOwnrPublicOther"
												onChange={this.onOtherOptionChange}
												value={this.props.propsFromIndex.estOwnrPublicOther}
												style={otherInputStyle}
											/>
										) : null}
									</Radio>
								</RadioGroup>
								{
									((this.props.propsFromIndex.estOwnrPublic.length === 0 || this.props.propsFromIndex.estOwnrPublic === 'other') && this.props.propsFromIndex.estOwnrPublicOther.length === 0 && this.props.propsFromIndex.isSubmit) &&
									<span className="form-error is-visible">Required</span>

								}
							</Col>
						)}
						{this.props.propsFromIndex.estOwnership === 'private' && (
							<Col span={12}>
								<Row>
									<label>Select a subtype</label>
								</Row>
								<RadioGroup
									name="estOwnrPrivate"
									onChange={this.onRadioChange}
									value={this.props.propsFromIndex.estOwnrPrivate}
								>
									<Radio style={radioStyle} value="proprietorship">
										Individual Proprietorship
									</Radio>
									<Radio style={radioStyle} value="partnership">
										Registered Partnership
									</Radio>
									<Radio style={radioStyle} value="company">
										Registered Company
									</Radio>
									<Radio style={radioStyle} value="corporation">
										Corporation/Society
										{this.props.propsFromIndex.estOwnrPrivate ===
											'corporation' ? (
												<input
													placeholder="please specify"
													name="corporationSpec"
													onChange={this.onOtherOptionChange}
													value={this.props.propsFromIndex.corporationSpec}
													style={otherInputStyle}
												/>
											) : null}
									</Radio>
									<Radio style={radioStyle} value="trust">
										Trust (including charitable)
										{this.props.propsFromIndex.estOwnrPrivate === 'trust' ? (
											<input
												placeholder="please specify"
												name="trustSpec"
												onChange={this.onOtherOptionChange}
												value={this.props.propsFromIndex.trustSpec}
												style={otherInputStyle}
											/>
										) : null}
									</Radio>
									<Radio style={radioStyle} value="foreign">
										Branch of a Foreign Service Provider
										{this.props.propsFromIndex.estOwnrPrivate === 'foreign' ? (
											<input
												placeholder="please specify"
												name="foreignSpec"
												onChange={this.onOtherOptionChange}
												value={this.props.propsFromIndex.foreignSpec}
												style={otherInputStyle}
											/>
										) : null}
									</Radio>
									<Radio style={radioStyle} value="other">
										Other
										{this.props.propsFromIndex.estOwnrPrivate === 'other' ? (
											<input
												placeholder="please specify"
												name="estOwnrPrivateOther"
												onChange={this.onOtherOptionChange}
												value={this.props.propsFromIndex.estOwnrPrivateOther}
												style={otherInputStyle}
											/>
										) : null}
									</Radio>
								</RadioGroup>
								{
									((this.props.propsFromIndex.estOwnrPrivate.length === 0 || this.props.propsFromIndex.estOwnrPrivate === 'other') && this.props.propsFromIndex.estOwnrPrivateOther.length === 0 && this.props.propsFromIndex.isSubmit) &&
									<span className="form-error is-visible">Required</span>

								}
							</Col>
						)}
					</Row>
				</Form>
			</div>
		);
	}
}
export default Second;
