import { Button, Layout, Menu, Steps } from "antd";
import moment from "moment";
import React, { Component } from "react";
import GeneratePdf from "./../generatePdf";
import Departments from "./departments";
import First from "./first";
import {
  FirstFormValidation,
  SecondFormValidation,
  ThirdFormValidation
} from "./FormValidator";
import Fourth from "./fourth";
import logo from "./logo.png";
import Others from "./others";
import Registration from "./registration";
import Second from "./second";
import Staffs from "./staffs";
import Systems from "./systems";
import Third from "./third";
import Airtable from "airtable";

const { Sider, Content, Header } = Layout;

const Step = Steps.Step;

class CeaRegistrationForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
      first: {
        form: React.createRef(),
        isSubmit: false,
        message: "",
        estName: "",
        estRegNumber: "",
        estAddressL1: "",
        estLocality: "",
        estTaluk: "",
        estDistrict: "",
        estPincode: "",
        estdYear: "",
        estTele: "",
        estMobile: "",
        estEmail: "",
        estWebsite: "",
        estFax: "",
        estType: "",
        labAttachment: "",
        imagingAttachment: "",
        bloodBankAttachment: "",
        estLocation: "",
        estotherLocation: ""
      },
      second: {
        form: React.createRef(),
        estOwnership: "",
        estOwnrPublic: "",
        isSubmit: false,
        estOwnrPublicOther: "",
        estOwnrPrivate: "",
        estOwnrPrivateOther: "",
        corporationSpec: "",
        trustSpec: "",
        foreignSpec: "",
        estOwnrLocalOther: ""
      },
      third: {
        form: React.createRef(),
        isSubmit: false,
        picName: "",
        picQual: "",
        picDesignation: "",
        picAddressL1: "",
        picLocality: "",
        picTaluk: "",
        picDistrict: "",
        picPincode: "",
        picFax: "",
        picTele: "",
        picMobile: "",
        picEmail: "",
        isOwner: true, //if false the below fields are applicable
        ownrName: "",
        ownrAddressL1: "",
        ownrLocality: "",
        ownrTaluk: "",
        ownrDistrict: "",
        ownrPincode: "",
        ownrFax: "",
        ownrTele: "",
        ownrMobile: "",
        ownrEmail: ""
      },
      fourth: {
        form: React.createRef(),
        isSubmit: false,
        clinicSubType: [],
        centreSubType: [],
        hospitalSubType: [],
        otherClinicSubType: "",
        otherCentreSubType: "",
        otherHospitalSubType: "",
        labSubType: [],
        imagingSubType: [],
        bloodBankSubType: "",
        otherLabSubType: "",
        otherImagingSubType: "",
        otherBBSubType: "",
        bbLocationType: "",
        otherBBLocationInput: "",
        bbFacilityType: "",
        otherBBFacilityInput: "",
        equipments: ""
      },
      fifth: {
        form: React.createRef(),
        isSubmit: false
      },
      sixth: {
        form: React.createRef(),
        isSubmit: false
      },
      opDepartment: [
        {
          speciality: "",
          numberOfRooms: ""
        },
        {
          speciality: "",
          numberOfRooms: ""
        },
        {
          speciality: "",
          numberOfRooms: ""
        }
      ],
      ipDepartment: [
        {
          speciality: "",
          numberOfBeds: ""
        },
        {
          speciality: "",
          numberOfBeds: ""
        },
        {
          speciality: "",
          numberOfBeds: ""
        }
      ],
      staffs: [
        {
          form: React.createRef(),
          name: "",
          qualification: "",
          regNumber: "",
          employmentNature: "",
          category: "",
          canSignTestReport: false,
          isTechnical: false
        }
      ],
      systems: {
        form: React.createRef(),
        isSubmit: false,
        systemType: "",
        allopathySubtypes: "",
        allopathyOtherSubtype: "",
        ayurvedaSubtypes: "",
        ayurvedaOtherSubtype: "",
        unaniSubtypes: "",
        unaniOtherSubtype: "",
        siddhaSubtypes: "",
        siddhaOtherSubtype: "",
        homeopathySubtypes: "",
        homeopathyOtherSubtype: "",
        naturopathySubtypes: "",
        naturopathyOtherSubtype: "",
        yogaSubtypes: "",
        yogaOtherSubtype: ""
      },
      misc: {
        isSubmit: false,
        totalArea: null,
        constructedArea: null,
        disposal: "",
        disposalOther: "",
        authorization: ""
      },
      registration: {
        form: React.createRef(),
        isSubmit: false,
        pymntOption: null,
        details: "",
        amount: 5000,
        date: new Date(),
        place: ""
      },
      tags: [],
      inputVisible: false,
      inputValue: "",
      tags1: [],
      inputVisible1: false,
      inputValue1: "",
      testnequSubmit: false
    };
  }
  resetSystemsFields = callback => {
    const newSystems = {
      form: React.createRef(),
      isSubmit: false,
      systemType: this.state.systems.systemType,
      allopathySubtypes: "",
      allopathyOtherSubtype: "",
      ayurvedaSubtypes: "",
      ayurvedaOtherSubtype: "",
      unaniSubtypes: "",
      unaniOtherSubtype: "",
      siddhaSubtypes: "",
      siddhaOtherSubtype: "",
      homeopathySubtypes: "",
      homeopathyOtherSubtype: "",
      naturopathySubtypes: "",
      naturopathyOtherSubtype: "",
      yogaSubtypes: "",
      yogaOtherSubtype: ""
    };
    this.setState({ systems: newSystems });
  };
  next1() {
    this.setState({ current: this.state.current + 1 });
  }
  sendDocInfo = () => {
    let docInfo = {
      fields: {
        name: this.state.first.docName,
        email: this.state.first.docEmail,
        phone: this.state.first.docMobile
      }
    };
    fetch("https://api.airtable.com/v0/appzoaZ2Lm23xkJ6e/info", {
      method: "POST", // or 'PUT'
      body: JSON.stringify(docInfo), // data can be `string` or {object}!
      headers: {
        Authorization: "Bearer keyvcKK7SmbvCbo1S",
        "Content-Type": "application/json"
      }
    })
      .then(res => res.json())
      .then(response => console.log("Success:", JSON.stringify(response)))
      .catch(error => console.error("Error:", error));
  };
  next() {
    const current = this.state.current + 1;
    if (this.state.current === 0) {
      console.log("clicked next");

      const state = { ...this.state };
      state.first.isSubmit = true;
      // FirstFormValidation();
      state.first.message = FirstFormValidation(state.first);
      this.setState({ first: state.first });

      console.log(this.state.first.form.current);
      // console.log(this.state.first.form.current.hasErrors())
      // console.log(this.state.first.form.current.validate(this.state.first.form))
      this.state.first.form.current.validateAll();
      setTimeout(() => {
        if (
          state.first.message === "" &&
          document.querySelectorAll(".form-error").length === 0
        ) {
          this.sendDocInfo();
          this.setState({ current });
          state.first.isSubmit = false;
          this.setState({ first: state.first });
        }
      }, 1);
    }

    if (this.state.current === 1) {
      const state = { ...this.state };
      state.fourth.isSubmit = true;
      // FirstFormValidation();
      //state.first.message = FirstFormValidation(state.first);
      this.setState({ fourth: state.fourth });
      this.state.fourth.form.current.validateAll();
      const error = SecondFormValidation(
        state.fourth,
        this.state.first.estType,
        this.state.first.bloodBankAttachment,
        this.state.first.imagingAttachment,
        this.state.first.labAttachment
      );
      setTimeout(() => {
        if (
          error === "" &&
          document.querySelectorAll(".form-error").length === 0
        ) {
          this.setState({ current });
          state.fourth.isSubmit = false;
          this.setState({ fourth: state.fourth });
        }
      }, 1);
    }
    if (this.state.current === 2) {
      const state = { ...this.state };
      state.second.isSubmit = true;
      // FirstFormValidation();
      //state.first.message = FirstFormValidation(state.first);
      this.setState({ second: state.second });
      this.state.second.form.current.validateAll();
      setTimeout(() => {
        if (
          ThirdFormValidation(state.second) === 1 &&
          document.querySelectorAll(".form-error").length === 0
        ) {
          this.setState({ current });
          state.second.isSubmit = false;
          this.setState({ second: state.second });
        }
      }, 1);
      //SecondFormValidation(state.fourth);
      // this.setState({ current });
    }
    if (this.state.current === 3) {
      this.state.third.form.current.validateAll();
      setTimeout(() => {
        if (document.querySelectorAll(".form-error").length === 0) {
          this.setState({ current });
        }
      }, 1);
    }
    if (this.state.current === 4) {
      const state = { ...this.state };
      state.systems.isSubmit = true;
      // FirstFormValidation();
      //state.first.message = FirstFormValidation(state.first);
      this.setState({ systems: state.systems });
      this.state.systems.form.current.validateAll();
      setTimeout(() => {
        if (document.querySelectorAll(".form-error").length === 0) {
          state.systems.isSubmit = false;
          this.setState({ systems: state.systems });
          this.setState({ current });
        }
      }, 1);
    }
    if (this.state.current === 5) {
      const state = { ...this.state };
      state.fifth.isSubmit = true;
      this.setState({ fifth: state.fifth });
      this.state.fifth.form.current.validateAll();
      setTimeout(() => {
        if (document.querySelectorAll(".form-error").length === 0) {
          state.fifth.isSubmit = false;
          this.setState({ fifth: state.fifth });
          this.setState({ current });
        }
      }, 1);
    }
    if (this.state.current === 6) {
      const state = { ...this.state };
      state.sixth.isSubmit = true;
      // FirstFormValidation();
      //state.first.message = FirstFormValidation(state.first);
      this.setState({ sixth: state.sixth });
      this.state.sixth.form.current.validateAll();
      setTimeout(() => {
        if (document.querySelectorAll(".form-error").length === 0) {
          state.sixth.isSubmit = false;
          this.setState({ sixth: state.sixth });
          this.setState({ current });
        }
      }, 1);
    }
    if (this.state.current === 7) {
      this.state.testnequSubmit = true;
      setTimeout(() => {
        if (document.querySelectorAll(".form-error").length === 0) {
          this.state.testnequSubmit = false;
          this.setState({ current });
        }
      }, 1);
    }
    if (this.state.current === 8) {
      const state = { ...this.state };
      state.registration.isSubmit = true;
      this.setState({ registration: state.registration });

      this.state.registration.form.current.validateAll();
      setTimeout(() => {
        if (document.querySelectorAll(".form-error").length === 0) {
          state.registration.isSubmit = false;
          this.setState({ registration: state.registration });
          this.setState({ current });
          // console.log(current);
        }
      }, 1);
    }

    //this.state.first.form.validateAll();
    //this.setState({ current });
  }

  prev() {
    const current = this.state.current - 1;
    this.setState({ current });
  }
  print() {
    fetch("https://cea-api.slashdr.com/cea_string", {
      method: "POST", // or 'PUT'
      body: JSON.stringify(this.state), // data can be `string` or {object}!
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(res => res.json())
      .then(response => console.log("Success:", JSON.stringify(response)))
      .catch(error => console.error("Error:", error));
  }

  updateFirst = (key, value) => {
    const { first } = this.state;

    first[key] = value;
    this.setState({ first });
  };

  updateSecond = (key, value) => {
    const { second } = this.state;

    second[key] = value;
    this.setState({ second });
  };

  updateThird = (key, value) => {
    const { third } = this.state;

    third[key] = value;
    this.setState({ third });
  };

  updateFourth = (key, value) => {
    const { fourth } = this.state;

    fourth[key] = value;
    this.setState({ fourth });
  };
  //OPD
  updateOpd = (index, e) => {
    let deptKey = this.state.opDepartment[index];
    deptKey[e.target.name] = e.target.value;
    this.setState({ deptKey });
  };
  addOpd = e => {
    e.preventDefault();
    const obj = {
      speciality: "",
      numberOfRooms: ""
    };
    let opdCopy = this.state;
    opdCopy.opDepartment.push(obj);
    let x = opdCopy.opDepartment;
    this.setState({
      opDepartment: x
    });
  };
  delOpd = (index, e) => {
    e.preventDefault();
    console.log(index);
    let opdCopy = this.state;
    let removeSpeciality = opdCopy.opDepartment.splice(index, 1);
    let x = opdCopy.opDepartment;
    this.setState({ opDepartment: x });
    this.forceUpdate();
  };
  //IPD
  updateIpd = (index, e) => {
    let deptKey = this.state.ipDepartment[index];
    deptKey[e.target.name] = e.target.value;
    this.setState({ deptKey });
  };
  addIpd = e => {
    e.preventDefault();
    const obj = {
      speciality: "",
      numberOfBeds: ""
    };
    let ipdCopy = this.state;
    ipdCopy.ipDepartment.push(obj);
    let x = ipdCopy.ipDepartment;
    this.setState({
      ipDepartment: x
    });
  };
  delIpd = (index, e) => {
    e.preventDefault();
    console.log(index);
    let ipdCopy = this.state;
    let removeSpeciality = ipdCopy.ipDepartment.splice(index, 1);
    let x = ipdCopy.ipDepartment;
    this.setState({ ipDepartment: x });
  };
  //misc
  updateMisc = e => {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    const name = e.target.name;
    let miscKey = this.state.misc;
    miscKey[name] = value;
    this.setState({ miscKey });
  };
  //staffs
  updateStaffs = (index, e) => {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    const name = e.target.name;
    let staffsKey = this.state.staffs[index];
    staffsKey[name] = value;
    this.setState({ staffsKey });
  };
  //others
  updateOthers = (index, e) => {
    console.log(index);
    console.log(e);
    // const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    // const name = e.target.name;
    // let staffsKey = this.state.staffs[index];
    // staffsKey[name] = value;
    // this.setState({ staffsKey });
  };
  //registration
  updateRegistration = e => {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    const name = e.target.name;
    let regKey = this.state.registration;
    regKey[name] = value;
    this.setState({ regKey });
  };
  onDateChange = (date, dateString) => {
    let regKey = this.state.registration;
    let formattedDate = moment(date).format("DD/MM/YYYY");
    regKey["date"] = date;

    this.setState({ regKey });
    // console.log(regKey);
  };
  //staffs
  addStaff = e => {
    e.preventDefault();
    const obj = {
      name: "",
      qualification: "",
      regNumber: "",
      employmentNature: "",
      category: "",
      canSignTestReport: "",
      isTechnical: ""
    };
    let staffsCopy = this.state;
    staffsCopy.staffs.push(obj);
    let x = staffsCopy.staffs;
    this.setState({
      staffs: x
    });
  };
  delStaff = (index, e) => {
    e.preventDefault();
    let staffsCopy = this.state;
    let removeStaff = staffsCopy.staffs.splice(index, 1);
    let x = staffsCopy.staffs;
    this.setState({ staffs: x });
  };
  //systems
  updateSystems = (key, value) => {
    const { systems } = this.state;

    systems[key] = value;
    this.setState({ systems });
  };

  //othersFinal
  handleInputConfirm = e => {
    e.preventDefault();
    const state = this.state;
    const inputValue = state.inputValue;
    let tags = state.tags;
    if (inputValue && tags.indexOf(inputValue) === -1) {
      tags = [...tags, inputValue];
    }
    console.log(tags);
    this.setState({
      tags,
      inputVisible: false,
      inputValue: ""
    });
  };
  handleClose = removedTag => {
    const tags = this.state.tags.filter(tag => tag !== removedTag);
    console.log(tags);
    this.setState({ tags });
  };

  showInput = () => {
    this.setState({ inputVisible: true }, () => this.input.focus());
  };

  handleInputChange = e => {
    this.setState({ inputValue: e.target.value });
  };
  saveInputRef = input => (this.input = input);
  //othersFinal1
  // TODO: change name and add to props
  handleInputConfirm1 = e => {
    e.preventDefault();
    const state = this.state;
    const inputValue = state.inputValue1;
    let tags1 = state.tags1;
    if (inputValue && tags1.indexOf(inputValue) === -1) {
      tags1 = [...tags1, inputValue];
    }
    console.log(tags1);
    this.setState({
      tags1,
      inputVisible1: false,
      inputValue1: ""
    });
  };
  handleClose1 = removedTag => {
    const tags1 = this.state.tags1.filter(tag1 => tag1 !== removedTag);
    console.log(tags1);
    this.setState({ tags1 });
  };

  showInput1 = () => {
    this.setState({ inputVisible1: true }, () => this.input.focus());
  };

  handleInputChange1 = e => {
    this.setState({ inputValue1: e.target.value });
  };
  saveInputRef1 = input => (this.input = input);

  componentDidMount() {
    let defaultDate = moment(new Date(), "DD/MM/YYYY");
    this.setState({ date: defaultDate });
  }
  render() {
    const { current } = this.state;
    const trigger = (
      <Button
        style={{ width: 100 }}
        type="primary"
        id="printMe"
        onClick={() => this.print.bind(this)}
      >
        Print
      </Button>
    );
    const steps = [
      {
        title: "Est. details",
        content: (
          <First update={this.updateFirst} propsFromIndex={this.state.first} />
        )
      },
      {
        title: "Add. Est. details",
        content: (
          <Fourth
            update={this.updateFourth}
            propsForFirst={this.state.first}
            propsForFourth={this.state.fourth}
          />
        )
      },
      {
        title: "Ownership of service",
        content: (
          <Second
            update={this.updateSecond}
            propsFromIndex={this.state.second}
          />
        )
      },
      {
        title: "In-charge and Ownership",
        content: (
          <Third update={this.updateThird} propsFromIndex={this.state.third} />
        )
      },
      {
        title: "Systems of Medicine",
        content: (
          <Systems
            update={this.updateSystems}
            systems={this.state.systems}
            resetSystemFields={this.resetSystemsFields}
          />
        )
      },
      {
        title: "Infrastructure",
        content: (
          <Departments
            propsFromIndex={this.state.fifth}
            update={this.updateDepartments}
            updateMisc={this.updateMisc}
            misc={this.state.misc}
            opd={this.state.opDepartment}
            ipd={this.state.ipDepartment}
            updateOpd={this.updateOpd}
            addOpd={this.addOpd}
            delOpd={this.delOpd}
            updateIpd={this.updateIpd}
            addIpd={this.addIpd}
            delIpd={this.delIpd}
          />
        )
      },
      {
        title: "Human Resources",
        content: (
          <Staffs
            update={this.updateStaffs}
            staffs={this.state.staffs}
            addStaff={this.addStaff}
            propsFromIndex={this.state.sixth}
            delStaff={this.delStaff}
            updateStaffs={this.updateStaffs}
          />
        )
      },
      {
        title: "Tests & Equipments",
        content: (
          <Others
            update={this.updateOthers}
            others={this.state.others}
            deets={this.state}
            handleInputConfirm={this.handleInputConfirm}
            handleClose={this.handleClose}
            showInput={this.showInput}
            handleInputChange={this.handleInputChange}
            saveInputRef={this.saveInputRef}
            handleInputConfirm1={this.handleInputConfirm1}
            handleClose1={this.handleClose1}
            showInput1={this.showInput1}
            handleInputChange1={this.handleInputChange1}
            saveInputRef1={this.saveInputRef1}
          />
        )
      },
      {
        title: "Registration Fees",
        content: (
          <Registration
            update={this.updateRegistration}
            onDateChange={this.onDateChange}
            registration={this.state.registration}
            second={this.state.second}
          />
        )
      },
      {
        title: "Preview & Submit",
        content: <GeneratePdf state={this.state} trigger={trigger} />
      }
    ];
    return (
      <div>
        <Layout>
          <Header className="header">
            <img
              className="logo"
              style={{ maxWidth: 45, float: "left", marginTop: 16 }}
              src={logo}
            />
            <Menu
              theme="dark"
              mode="horizontal"
              style={{ lineHeight: "64px" }}
              selectable={false}
            >
              <Menu.Item key="1">
                CEA Registration Form - Powered by{" "}
                <a
                  href="https://slashdr.com/"
                  className="poweredBy"
                  target="_blank"
                >
                  SlashDr
                </a>
              </Menu.Item>
            </Menu>
          </Header>
        </Layout>
        <Layout className="vh100">
          <Sider theme="light" className="sidebar" width={300}>
            {/* <Tag color="#108ee9" className="tagss">CEA v{packageJson.version}</Tag> */}
            <Steps current={current} direction="vertical" size="small">
              {steps.map((item, index) => (
                // <Step key={item.title} title={`${item.title} ${index}`} />
                <Step key={item.title} title={`${item.title}`} />
              ))}
            </Steps>
          </Sider>
          <Layout className="bg-white reset-margin">
            <Content className="main-content">
              <div className="steps-content">{steps[current].content}</div>
              <div className="steps-action">
                {current > 0 && (
                  <Button
                    style={{ marginRight: 8, width: 100 }}
                    onClick={() => this.prev()}
                  >
                    {/* <Icon type="left" /> */}
                    Previous
                  </Button>
                )}
                {current < steps.length - 1 && (
                  <Button
                    type="primary"
                    style={{ width: 100 }}
                    onClick={() => {
                      this.next();
                    }}
                  >
                    Next
                    {/* <Icon type="right" /> */}
                  </Button>
                )}
                {current === steps.length - 1 && (
                  <Button
                    style={{ width: 100 }}
                    type="primary"
                    onClick={() => {
                      document.getElementById("printMe").click();
                      this.print();
                    }}
                  >
                    Print
                  </Button>
                  // trigger
                )}
              </div>
            </Content>
          </Layout>
        </Layout>
      </div>
    );
  }
}
export default CeaRegistrationForm;
