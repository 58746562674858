import { Button, Col, Divider, Icon, Radio, Row } from 'antd';
import React, { Component } from 'react';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import { isMin, required } from './FormValidator';

const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
class Departments extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	onChange = e => {
		this.props.update(e.target.name, e.target.value);
	};
	onRadioChange = e => {
		console.log(e.target.name, e.target.value);
		this.props.update(e.target.name, e.target.value);
	};
	onOtherOptionChange = e => {
		this.props.updateMisc(e.target.name, e.target.value);
	};
	chchch = (index, e) => {
		console.log(e);
		console.log(index);
	};

	render() {
		const radioStyle = {
			display: 'block',
			height: '30px',
			lineHeight: '30px'
		};
		const otherInputStyle = { width: 150, marginLeft: 10 };
		return (
			<div>
				<Form ref={this.props.propsFromIndex.form} className="firstForm">
					<React.Fragment key="opdFrag">
						<h1>Infrastructure</h1>
						<Row>
							<h3 className="deptHeader">Out-Patient Departments</h3>
							<Col span={12}>
								{this.props.opd.map((dept, index) => {
									return (
										<React.Fragment key={index}>
											<Col span={10}>
												<label>Speciality</label>
												<Input
													key={index}
													type="text"
													name="speciality"
													value={dept.speciality}
													onChange={this.props.updateOpd.bind(this, index)}
												/>
											</Col>
											<Col span={10}>
												<label>Number of Rooms</label>
												<Input
													key={index}
													type="number"
													min="1"
													name="numberOfRooms"
													value={dept.numberOfRooms}
													onChange={this.props.updateOpd.bind(this, index)}
												/>
											</Col>
											<Col span={4}>
												{this.props.opd.length > 1 && (
													<Button
														key={index}
														type="danger"
														ghost
														onClick={this.props.delOpd.bind(this, index)}
														style={{ marginTop: 34 }}
													>
														<Icon type="minus" />
													</Button>
												)}
											</Col>
										</React.Fragment>
									);
								})}
							</Col>
						</Row>
						<Row>
							<Col span={4}>
								<Button
									type="primary"
									ghost
									onClick={this.props.addOpd}
									style={{ marginTop: 10, width: '50%' }}
								>
									<Icon type="plus" /> Add
								</Button>
							</Col>
						</Row>
					</React.Fragment>
					<React.Fragment key="ipdFrag">
						<Row>
							<h3 className="deptHeader">In-Patient Departments</h3>
							<Col span={12}>
								{this.props.ipd.map((dept, index) => {
									return (
										<React.Fragment key={index}>
											<Col span={10}>
												<label>Speciality</label>
												<Input
													key={index}
													type="text"
													name="speciality"
													value={dept.speciality}
													onChange={this.props.updateIpd.bind(this, index)}
												/>
											</Col>
											<Col span={10}>
												<label>Number of Beds</label>
												<Input
													key={index}
													type="number"
													name="numberOfBeds"
													value={dept.numberOfBeds}
													onChange={this.props.updateIpd.bind(this, index)}
												/>
											</Col>
											<Col span={4}>
												{this.props.ipd.length > 1 && (
													<Button
														type="danger"
														ghost
														onClick={this.props.delIpd.bind(this, index)}
														style={{ marginTop: 34 }}
													>
														<Icon type="minus" />
													</Button>
												)}
											</Col>
										</React.Fragment>
									);
								})}
							</Col>
						</Row>
						<Row>
							<Col span={4}>
								<Button
									type="primary"
									ghost
									onClick={this.props.addIpd}
									style={{ marginTop: 10, width: '50%' }}
								>
									<Icon type="plus" /> Add
								</Button>
							</Col>
						</Row>
					</React.Fragment>

					<React.Fragment key="area">
						<Row>
							<Divider orientation="left">Area of the establishment</Divider>
						</Row>
						<Row>
							<Col span={5}>
								<label>Total Area (sq.ft)</label>
								<Input
									validations={[required]}
									name="totalArea"
									value={this.props.misc.totalArea}
									onChange={this.props.updateMisc.bind(this)}
								/>
							</Col>
							<Col span={5}>
								<label>Constructed Area (sq.ft)</label>
								<Input
									name="constructedArea"
									validations={[required]}
									value={this.props.misc.constructedArea}
									onChange={this.props.updateMisc.bind(this)}
								/>
							</Col>
						</Row>
					</React.Fragment>
					<React.Fragment key="wasteManagement">
						<Row>
							<Divider orientation="left">Biomedical waste management</Divider>
						</Row>
						<Row>
							<Col span={8}>
								<label>Method of treatment and/or disposal of bio medical waste</label>
								<div className="position-relative">
									<RadioGroup
										name="disposal"
										onChange={this.props.updateMisc.bind(this)}
										value={this.props.misc.disposal}
									>
										<Radio style={radioStyle} value="Through common facility">
											Through common facility
									</Radio>
										<Radio style={radioStyle} value="Onsite">
											Onsite Facility
									</Radio>
										<Radio style={radioStyle} value="other">
											Other
										{this.props.misc.disposal === 'other' ? (
												<input
													type="text"
													placeholder="please specify"
													name="disposalOther"
													onChange={this.props.updateMisc.bind(this)}
													value={this.props.misc.disposalOther}
													style={otherInputStyle}
												/>
											) : null}
										</Radio>
									</RadioGroup>

									{(((this.props.misc.disposal === "" || this.props.misc.disposal === 'other') && this.props.misc.disposalOther.length === 0) && this.props.propsFromIndex.isSubmit) &&
										<span className="form-error is-visible">Required</span>
									}
								</div>
								<label>Authorization from pollution control board obtained?</label>
								<div className="position-relative">
									<RadioGroup
										name="authorization"
										onChange={this.props.updateMisc.bind(this)}
										value={this.props.misc.authorization}
									>
										<Radio style={radioStyle} value="yes">
											Yes
									</Radio>
										<Radio style={radioStyle} value="no">
											No
									</Radio>
										<Radio style={radioStyle} value="applied for">
											Applied for
									</Radio>
										<Radio style={radioStyle} value="not applicable">
											Not applicable
									</Radio>
									</RadioGroup>
									{(this.props.misc.authorization === "") && this.props.propsFromIndex.isSubmit &&
										<span className="form-error is-visible">Required</span>
									}
								</div>
							</Col>
						</Row>
					</React.Fragment>
				</Form>
			</div>
		);
	}
}
export default Departments;
