import React, { Component } from "react";
import { Row, Col } from "antd";
import "./styles.css";

class HRPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    console.log(this.props);
  }

  render() {
    const {
      first,
      second,
      third,
      fourth,
      ipDepartment,
      opDepartment,
      systems,
      staffs
    } = this.props.state;
    let { annexureNo } = this.props;
    const totalNoPermanentStaffs = staffs.filter(
      x => x.employmentNature === "Permanent"
    ).length;
    const totalNoTempStaffs = staffs.filter(
      x => x.employmentNature !== "Permanent"
    ).length;
    return (
      <div className="staff-table is-center">
        <Row className="is-center text-left">
          <Col offset={2} span={20}>
            <p className="text-center heading">ANNEXURE {annexureNo}</p>
          </Col>
          <Col offset={1} span={23}>
            <p className="question">
              Total number of Staff (as on date of application)
            </p>
          </Col>
          <Col offset={1} span={12}>
            <p className="question inline-block">Number of permanent staff:</p>{" "}
            <span className="answer inline-block">
              {totalNoPermanentStaffs}
            </span>
          </Col>
          <Col span={10} pull={1}>
            <p className="question inline-block">Number of temporary staff:</p>{" "}
            <span className="answer inline-block">{totalNoTempStaffs} </span>
          </Col>
        </Row>
        <Row className="is-center text-left">
          <Col offset={1} span={4} className="border-box">
            <p>Category of Staff</p>
          </Col>

          <Col span={4} className="border-box">
            <p>Name</p>
          </Col>

          <Col span={3} className="border-box">
            <p>Qualification</p>
          </Col>

          <Col span={4} className="border-box">
            <p>Registration No.</p>
          </Col>

          <Col span={5} className="border-box">
            <p>Nature of Service</p>
          </Col>
        </Row>
        {staffs.map(item => {
          return (
            <div>
              <Row className="is-center text-left">
                <Col offset={1} span={4} className="border-box">
                  <p className="answer">{item.category}</p>
                </Col>

                <Col span={4} className="border-box">
                  <p className="answer">{item.name}</p>
                </Col>

                <Col span={3} className="border-box">
                  <p className="answer">{item.qualification}</p>
                </Col>

                <Col span={4} className="border-box">
                  <p className="answer">{item.regNumber}</p>
                </Col>

                <Col span={5} className="border-box">
                  <p className="answer">
                    {item.employmentNature === "Permanent"
                      ? "Permanent"
                      : "Temporary"}
                  </p>
                </Col>
              </Row>
            </div>
          );
        })}
      </div>
    );
  }
}

export default HRPage;
