import { Checkbox, Col, Divider, Input, Radio, Row } from 'antd';
import React, { Component } from 'react';
import Form from 'react-validation/build/form';

class Fourth extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	onChange = e => {
		this.props.update(e.target.name, e.target.value);
	};
	onRadioChange = e => {
		console.log(e.target.name, e.target.value);
		this.props.update(e.target.name, e.target.value);
	};
	onOtherOptionChange = e => {
		this.props.update(e.target.name, e.target.value);
	};
	onClinicSubTypeChange = e => {
		this.props.update('clinicSubType', e);
	};
	onCentreSubTypeChange = e => {
		this.props.update('centreSubType', e);
	};
	onHospitalSubTypeChange = e => {
		this.props.update('hospitalSubType', e);
	};
	onLabSubTypeChangeChange = e => {
		this.props.update('labSubType', e);
	};
	onImagingSubTypeChangeChange = e => {
		this.props.update('imagingSubType', e);
	};
	onBloodBankSubTypeChangeChange = e => {
		this.props.update('bloodBankSubType', e);
	};

	render() {
		const { estType } = this.props.propsForFirst.estType;
		const radioStyle = {
			display: 'block',
			height: '30px',
			lineHeight: '30px'
		};
		const otherInputStyle = {
			width: 180,
			marginLeft: 20,
			marginRight: 20
		};
		return (
			<div>
				<Form ref={this.props.propsForFourth.form} className="firstForm">
					<h1>Addtional establishment details</h1>
					<Row>
						<Divider orientation="left">
							Type of {this.props.propsForFirst.estType}
						</Divider>
						<Col span={24}>
							{this.props.propsForFirst.estType === 'clinic' && (
								<Checkbox.Group
									name="clinicSubType"
									style={{ width: '100%' }}
									value={this.props.propsForFourth.clinicSubType}
									onChange={this.onClinicSubTypeChange}
								>
									<Row>
										<Col span={8}>
											<Checkbox value="single">Single Practitioner</Checkbox>
										</Col>
										<Col span={8}>
											<Checkbox value="consulting">Consulting Room</Checkbox>
										</Col>
									</Row>
									<Row>
										<Col span={8}>
											<Checkbox value="polyclinic">Polyclinic</Checkbox>
										</Col>
										<Col span={8}>
											<Checkbox value="dental">Dental</Checkbox>
										</Col>
									</Row>
									<Row style={{ marginTop: 25 }}>
										<Col span={8}>
											<Input
												placeholder="please specify"
												name="otherClinicSubType"
												addonBefore="Other"
												// disabled={this.props.propsForFourth.clinicSubType.length !== 0}
												onChange={this.onOtherOptionChange}
												value={this.props.propsForFourth.otherClinicSubType}
											/>
										</Col>
									</Row>
								</Checkbox.Group>

							)}
							{
								(this.props.propsForFirst.estType === 'clinic' && (this.props.propsForFourth.clinicSubType.length === 0 && this.props.propsForFourth.otherClinicSubType.length === 0) && this.props.propsForFourth.isSubmit) &&
								<span className="form-error is-visible">Required</span>
							}
							{this.props.propsForFirst.estType === 'centre' && (
								<Checkbox.Group
									name="centreSubType"
									style={{ width: '100%' }}
									value={this.props.propsForFourth.centreSubType}
									onChange={this.onCentreSubTypeChange}
								>
									<Row>
										<Col span={8}>
											<Checkbox value="phc">Primary Health Centre</Checkbox>
										</Col>
										<Col span={8}>
											<Checkbox value="chc">Community Health Centre</Checkbox>
										</Col>
										<Col span={8}>
											<Checkbox value="uhc">Urban Health Centre</Checkbox>
										</Col>
									</Row>
									<Row>
										<Col span={8}>
											<Checkbox value="dispensary">Dispensary</Checkbox>
										</Col>
										<Col span={8}>
											<Checkbox value="daycare">Day Care Centre</Checkbox>
										</Col>
										<Col span={8}>
											<Checkbox value="counselling">
												Counselling Centre
											</Checkbox>
										</Col>
									</Row>
									<Row>
										<Col span={8}>
											<Checkbox value="physiotherapy">
												Physiotherapy Centre
											</Checkbox>
										</Col>
										<Col span={8}>
											<Checkbox value="yoga">Yoga Centre</Checkbox>
										</Col>
										<Col span={8}>
											<Checkbox value="ivf">
												In Vitro Fertilization (IVF) Centre
											</Checkbox>
										</Col>
									</Row>
									<Row>
										<Col span={8}>
											<Checkbox value="dialysis">Dialysis</Checkbox>
										</Col>
										<Col span={8}>
											<Checkbox value="hospice">Hospice Centre</Checkbox>
										</Col>
									</Row>
									<Row style={{ marginTop: 25 }}>
										<Col span={8}>
											<Input
												placeholder="please specify"
												name="otherCentreSubType"
												// disabled={this.props.propsForFourth.centreSubType.length !== 0}
												onChange={this.onOtherOptionChange}
												addonBefore="Other"
												value={this.props.propsForFourth.otherCentreSubType}

											/>
										</Col>
									</Row>
								</Checkbox.Group>

							)

							}
							{
								(this.props.propsForFirst.estType === 'centre' && (this.props.propsForFourth.centreSubType.length === 0 && this.props.propsForFourth.otherCentreSubType.length === 0) && this.props.propsForFourth.isSubmit) &&
								<span className="form-error is-visible">Required</span>

							}
							{this.props.propsForFirst.estType === 'hospital' && (
								<Checkbox.Group
									name="clinicSubType"
									style={{ width: '100%' }}
									value={this.props.propsForFourth.hospitalSubType}
									onChange={this.onHospitalSubTypeChange}
								>
									<Row>
										<Col span={8}>
											<Checkbox value="general practice services">
												General Practice Services
											</Checkbox>
										</Col>
										<Col span={8}>
											<Checkbox value="maternity home">Maternity home</Checkbox>
										</Col>
										<Col span={8}>
											<Checkbox value="single speciality services">
												Single speciality services
											</Checkbox>
										</Col>
									</Row>
									<Row>
										<Col span={8}>
											<Checkbox value="multi speciality services">
												Multi speciality services
											</Checkbox>
										</Col>
										<Col span={8}>
											<Checkbox value="super  speciality services">
												Super speciality services
											</Checkbox>
										</Col>
										<Col span={8}>
											<Checkbox value="operation theatre">
												Operation theatre
											</Checkbox>
										</Col>
									</Row>
									<Row>
										<Col span={8}>
											<Checkbox value="emergency casuality">
												Emergency casuality
											</Checkbox>
										</Col>
										<Col span={8}>
											<Checkbox value="intensive care unit">
												Intensive care unit
											</Checkbox>
										</Col>
										<Col span={8}>
											<Checkbox value="ICCU">ICCU</Checkbox>
										</Col>
									</Row>
									<Row style={{ marginTop: 25 }}>
										<Col span={8}>
											<Input
												placeholder="please specify"
												name="otherHospitalSubType"
												// disabled={this.props.propsForFourth.hospitalSubType.length !== 0}
												onChange={this.onOtherOptionChange}
												addonBefore="Other"
												value={this.props.propsForFourth.otherHospitalSubType}
											/>
										</Col>
									</Row>
								</Checkbox.Group>
							)}
							{
								(this.props.propsForFirst.estType === 'hospital' && (this.props.propsForFourth.hospitalSubType.length === 0 && this.props.propsForFourth.otherHospitalSubType.length === 0) && this.props.propsForFourth.isSubmit) &&
								<span className="form-error is-visible">Required</span>

							}
						</Col>
					</Row>
					{this.props.propsForFirst.labAttachment === 'yes' && (
						<React.Fragment>
							<Row>
								<Divider orientation="left">Details of Laboratory</Divider>
								<Checkbox.Group
									name="labSubType"
									style={{ width: '100%' }}
									value={this.props.propsForFourth.labSubType}
									onChange={this.onLabSubTypeChangeChange}
								>
									<Row>
										<Col span={8}>
											<Checkbox value="pathology">Pathology</Checkbox>
										</Col>
										<Col span={8}>
											<Checkbox value="haematology">Haematology</Checkbox>
										</Col>
										<Col span={8}>
											<Checkbox value="histopathology">Histopathology</Checkbox>
										</Col>
									</Row>
									<Row>
										<Col span={8}>
											<Checkbox value="cytology">Cytology</Checkbox>
										</Col>
										<Col span={8}>
											<Checkbox value="genetics">Genetics</Checkbox>
										</Col>
										<Col span={8}>
											<Checkbox value="samples collection centre">
												Samples collection centre
											</Checkbox>
										</Col>
									</Row>
									<Row>
										<Col span={8}>
											<Checkbox value="biochemistry">Biochemistry</Checkbox>
										</Col>
										<Col span={8}>
											<Checkbox value="microbiology">Microbiology</Checkbox>
										</Col>
									</Row>

									<Row style={{ marginTop: 25 }}>
										<Col span={8}>
											<Input
												placeholder="please specify"
												name="otherLabSubType"
												// disabled={this.props.propsForFourth.labSubType.length !== 0}
												onChange={this.onOtherOptionChange}
												addonBefore="Other"
												value={this.props.propsForFourth.otherLabSubType}
											/>
										</Col>
									</Row>
									{
										(this.props.propsForFirst.labAttachment === 'yes' && (this.props.propsForFourth.labSubType.length === 0 && this.props.propsForFourth.otherLabSubType.length === 0) && this.props.propsForFourth.isSubmit) &&
										<span className="form-error is-visible">Required</span>

									}
								</Checkbox.Group>
							</Row>
						</React.Fragment>
					)}
					{this.props.propsForFirst.imagingAttachment === 'yes' && (
						<React.Fragment>
							<Row>
								<Divider orientation="left">Details of Imaging Centre</Divider>
								<Checkbox.Group
									name="imagingSubType"
									style={{ width: '100%' }}
									value={this.props.propsForFourth.imagingSubType}
									onChange={this.onImagingSubTypeChangeChange}
								>
									<Row>
										<Col span={8}>
											<Checkbox value="Portable x ray">Portable X Ray</Checkbox>
										</Col>
										<Col span={8}>
											<Checkbox value="conventional x ray">
												Conventional X Ray
											</Checkbox>
										</Col>
										<Col span={8}>
											<Checkbox value="digital x ray">Digital X Ray</Checkbox>
										</Col>
									</Row>
									<Row>
										<Col span={8}>
											<Checkbox value="x ray with computed radiography system">
												X Ray with Computed Radiography System
											</Checkbox>
										</Col>
										<Col span={8}>
											<Checkbox value="ultra sound">Ultra sound</Checkbox>
										</Col>
										<Col span={8}>
											<Checkbox value="ultra sound with color doppler">
												Ultra sound with color Doppler
											</Checkbox>
										</Col>
									</Row>
									<Row>
										<Col span={8}>
											<Checkbox value="mammography">Mammography</Checkbox>
										</Col>
										<Col span={8}>
											<Checkbox value="orthopantomogram">
												orthopantomogram (OPG)
											</Checkbox>
										</Col>
										<Col span={8}>
											<Checkbox value="CT scan">CT scan</Checkbox>
										</Col>
									</Row>
									<Row>
										<Col span={8}>
											<Checkbox value="magentic resonance imaging">
												Magentic Resonance Imaging (MRI)
											</Checkbox>
										</Col>
										<Col span={8}>
											<Checkbox value="positron emission tomography">
												Positron Emission Tomography (PET)
											</Checkbox>
										</Col>
										<Col span={8}>
											<Checkbox value="bone densitometry">
												Bone Densitometry
											</Checkbox>
										</Col>
									</Row>
									<Row style={{ marginTop: 25 }}>
										<Col span={8}>
											<Checkbox value="uro-flowmetry">Uro-Flowmetry</Checkbox>
										</Col>
										<Col span={8}>
											<Input
												placeholder="please specify"
												name="otherImagingSubType"
												// disabled={this.props.propsForFourth.imagingSubType.length !== 0}

												onChange={this.onOtherOptionChange}
												addonBefore="Other"
												value={this.props.propsForFourth.otherImagingSubType}
											/>
										</Col>
									</Row>
								</Checkbox.Group>
								{
									(this.props.propsForFirst.imagingAttachment === 'yes' && (this.props.propsForFourth.imagingSubType.length === 0 && this.props.propsForFourth.otherImagingSubType.length === 0) && this.props.propsForFourth.isSubmit) &&
									<span className="form-error is-visible">Required</span>

								}
							</Row>
						</React.Fragment>
					)}
					{this.props.propsForFirst.bloodBankAttachment === 'yes' && (
						<React.Fragment>
							<Row>
								<Divider orientation="left">Details of Blood Bank</Divider>

								<Row>
									<label>Based on location</label>
									<Radio.Group
										className=""
										name="bbLocationType"
										onChange={this.onRadioChange}
										value={this.props.propsForFourth.bbLocationType}
									>
										<Radio style={radioStyle} value="standalone">
											Stand alone
										</Radio>
										<Radio style={radioStyle} value="hospitalBased">
											Hospital Based
										</Radio>
										<Radio style={radioStyle} value="otherBBLocation">
											Other
											{this.props.propsForFourth.bbLocationType ===
												'otherBBLocation' ? (
													<input
														name="otherBBLocationInput"
														onChange={this.onOtherOptionChange}
														value={this.props.propsForFourth.otherBBLocationInput}
														style={otherInputStyle}
														placeholder="please specify"
													/>
												) : (
													<input style={otherInputStyle} placeholder="please specify" disabled />
												)}
										</Radio>
									</Radio.Group>
									{
										(this.props.propsForFirst.bloodBankAttachment === 'yes' && ((this.props.propsForFourth.bbLocationType.length === 0 || this.props.propsForFourth.bbLocationType === 'otherBBLocation') && this.props.propsForFourth.otherBBLocationInput.length === 0) && this.props.propsForFourth.isSubmit) &&
										<span className="form-error is-visible">Required</span>

									}
								</Row>
								<Row>
									<label>Based on Facilities</label>
									<Radio.Group
										className=""
										name="bbFacilityType"
										onChange={this.onRadioChange}
										value={this.props.propsForFourth.bbFacilityType}
									>
										<Radio style={radioStyle} value="only whole blood">
											Blood bank/Centre having whole blood facility only
										</Radio>

										<Radio style={radioStyle} value="whole blood n component">
											Blood bank/Centre having whole blood and component
											facility only
										</Radio>

										<Radio style={radioStyle} value="otherBBFacility">
											Any other additional facility
											{this.props.propsForFourth.bbFacilityType ===
												'otherBBFacility' ? (
													<input
														name="otherBBFacilityInput"
														onChange={this.onOtherOptionChange}
														value={this.props.propsForFourth.otherBBFacilityInput}
														style={otherInputStyle}
														placeholder="please specify"
													/>
												) : (
													<input style={otherInputStyle} placeholder="please specify" disabled />
												)}
										</Radio>
									</Radio.Group>
									{
										(this.props.propsForFirst.bloodBankAttachment === 'yes' && ((this.props.propsForFourth.bbFacilityType.length === 0 || this.props.propsForFourth.bbFacilityType === "otherBBFacility") && this.props.propsForFourth.otherBBFacilityInput.length === 0) && this.props.propsForFourth.isSubmit) &&
										<span className="form-error is-visible">Required</span>

									}
								</Row>
							</Row>
						</React.Fragment>
					)}
					{/* {this.props.propsForFirst.labAttachment === 'yes' && (
						<React.Fragment>
							<Divider orientation="left">
								Details of equipments maintained with
							</Divider>
							<div className="position-relative">
								<Textarea rows="5"
									cols="50"
									placeholder="Enter Equipment Details"
									name="equipments" onChange={this.onChange} validations={[required]} />
							</div>


						</React.Fragment>
					)}
				</Form>
					)} */}
				</Form>
			</div>
		);
	}
}
export default Fourth;
