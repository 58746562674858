import { Checkbox, Col, Input, Radio, Row } from "antd";
import React, { Component } from "react";
import Form from "react-validation/build/form";
// import Input from 'react-validation/build/input';
import { required } from "./FormValidator";
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

// COMBAK:  change balaji's validation input to antd
class Systems extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onChange = e => {
    this.props.update(e.target.name, e.target.value);
  };
  onRadioChange = e => {
    console.log(e.target.name, e.target.value);
    this.props.update(e.target.name, e.target.value);
    this.props.resetSystemFields();
  };
  onOtherOptionChange = e => {
    this.props.update(e.target.name, e.target.value);
  };
  onAllopathySubTypeChange = e => {
    this.props.update("allopathySubtypes", e);
  };
  onAyurvedaSubTypeChange = e => {
    this.props.update("ayurvedaSubtypes", e);
  };
  onUnaniSubTypeChange = e => {
    this.props.update("unaniSubtypes", e);
  };
  onSiddhaSubTypeChange = e => {
    this.props.update("siddhaSubtypes", e);
  };
  onHomeopathySubTypeChange = e => {
    this.props.update("homeopathySubtypes", e);
  };
  onNaturopathySubTypeChange = e => {
    this.props.update("naturopathySubtypes", e);
  };
  onYogaSubTypeChange = e => {
    this.props.update("yogaSubtypes", e);
  };

  render() {
    const radioStyle = {
      display: "block",
      height: "30px",
      lineHeight: "30px"
    };
    const otherInputStyle = { width: 100, marginLeft: 10 };
    return (
      <div>
        <Form className="firstForm" ref={this.props.systems.form}>
          <Row>
            <Col span={24}>
              <h1>Systems of medicine</h1>
              <label>Services offered</label>
              <RadioGroup
                className="flexIt ant-col-24"
                name="systemType"
                onChange={this.onRadioChange}
                value={this.props.systems.systemType}
              >
                <Row className="ant-col-24">
                  <Col span={8}>
                    <Radio style={radioStyle} value="allopathic">
                      Allopathic
                    </Radio>
                    <Radio style={radioStyle} value="ayurveda">
                      Ayurveda
                    </Radio>
                    <Radio style={radioStyle} value="unani">
                      Unani
                    </Radio>
                    <Radio style={radioStyle} value="yoga">
                      Yoga
                    </Radio>
                  </Col>
                  <Col span={8}>
                    <Radio style={radioStyle} value="siddha">
                      Siddha
                    </Radio>
                    <Radio style={radioStyle} value="homeopathy">
                      Homeopathy
                    </Radio>
                    <Radio style={radioStyle} value="naturopathy">
                      Naturopathy
                    </Radio>
                  </Col>
                  {/* <Col span={8}>
										<Radio style={radioStyle} value="yoga">
											yoga
										</Radio>
									</Col> */}
                </Row>
                {/* <Row>
									<Radio style={radioStyle} value="yoga">
										yoga
									</Radio>
								</Row> */}
              </RadioGroup>
              {this.props.systems.systemType.length === 0 &&
                this.props.systems.isSubmit && (
                  <span className="form-error is-visible">Required</span>
                )}
            </Col>
          </Row>
          {/* <Row>
						<label>Speciality</label>
					</Row> */}

          {this.props.systems.systemType === "allopathic" && (
            <Row>
              <label>Speciality</label>
              <Col span={8}>
                <Checkbox.Group
                  name="allopathySubtypes"
                  style={{ width: "100%" }}
                  value={this.props.systems.allopathySubtypes}
                  onChange={this.onAllopathySubTypeChange}
                >
                  <Checkbox value="Medical">Medical</Checkbox>
                  <Checkbox value="Surgical">Surgical</Checkbox>
                  <Checkbox value="Obstetrics and Gynecology">
                    Obstetrics and Gynecology
                  </Checkbox>
                  <Checkbox value="Paediatrics">Paediatrics</Checkbox>
                  <Input
                    placeholder="please specify"
                    name="allopathyOtherSubtype"
                    onChange={this.onOtherOptionChange}
                    addonBefore="Other"
                    value={this.props.systems.allopathyOtherSubtype}
                  />
                </Checkbox.Group>
                {this.props.systems.allopathySubtypes.length === 0 &&
                  this.props.systems.allopathyOtherSubtype.length === 0 &&
                  this.props.systems.isSubmit && (
                    <span className="form-error is-visible">Required</span>
                  )}
              </Col>
            </Row>
          )}
          {this.props.systems.systemType === "ayurveda" && (
            <Row>
              <label>Speciality</label>
              <Col span={8}>
                <Checkbox.Group
                  name="ayurvedaSubtypes"
                  style={{ width: "100%" }}
                  value={this.props.systems.ayurvedaSubtypes}
                  onChange={this.onAyurvedaSubTypeChange}
                >
                  <Checkbox value="Anusadh Chikitsa">Anusadh Chikitsa</Checkbox>
                  <Checkbox value="Shalya Chikitsa">Shalya Chikitsa</Checkbox>
                  <Checkbox value="Shodhan Chikitsa">Shodhan Chikitsa</Checkbox>
                  <Checkbox value="Rasayana">Rasayana</Checkbox>
                  <Checkbox value="Pathya Vyavastha">Pathya Vyavastha</Checkbox>
                  <Input
                    placeholder="please specify"
                    name="ayurvedaOtherSubtype"
                    onChange={this.onOtherOptionChange}
                    addonBefore="Other"
                    value={this.props.systems.ayurvedaOtherSubtype}
                  />
                </Checkbox.Group>
                {this.props.systems.ayurvedaSubtypes.length === 0 &&
                  this.props.systems.ayurvedaOtherSubtype.length === 0 &&
                  this.props.systems.isSubmit && (
                    <span className="form-error is-visible">Required</span>
                  )}
              </Col>
            </Row>
          )}
          {this.props.systems.systemType === "unani" && (
            <Row>
              <label>Speciality</label>
              <Col span={8}>
                <Checkbox.Group
                  name="unaniSubtypes"
                  style={{ width: "100%" }}
                  value={this.props.systems.unaniSubtypes}
                  onChange={this.onUnaniSubTypeChange}
                >
                  <Checkbox value="Matab">Matab</Checkbox>
                  <Checkbox value="Jarahat">Jarahat</Checkbox>
                  <Checkbox value=" Ilaj-bit-Tadbeer">
                    Ilaj-bit-Tadbeer
                  </Checkbox>
                  <Checkbox value="Hifzan-e-Sehat">Hifzan-e-Sehat</Checkbox>
                  <Input
                    placeholder="please specify"
                    name="unaniOtherSubtype"
                    onChange={this.onOtherOptionChange}
                    addonBefore="Other"
                    value={this.props.systems.unaniOtherSubtype}
                  />
                </Checkbox.Group>
                {this.props.systems.unaniSubtypes.length === 0 &&
                  this.props.systems.unaniOtherSubtype.length === 0 &&
                  this.props.systems.isSubmit && (
                    <span className="form-error is-visible">Required</span>
                  )}
              </Col>
            </Row>
          )}
          {this.props.systems.systemType === "siddha" && (
            <Row>
              <label>Speciality</label>
              <Col span={8}>
                <Checkbox.Group
                  name="siddhaSubtypes"
                  style={{ width: "100%" }}
                  value={this.props.systems.siddhaSubtypes}
                  onChange={this.onSiddhaSubTypeChange}
                >
                  <Checkbox value="Maruthuvam">Maruthuvam</Checkbox>
                  <Checkbox value=" Sirappu Maruthuvam">
                    {" "}
                    Sirappu Maruthuvam
                  </Checkbox>
                  <Checkbox value="Varmam Thokknam & Yoga">
                    {" "}
                    Varmam Thokknam & Yoga
                  </Checkbox>
                  <Input
                    placeholder="please specify"
                    name="siddhaOtherSubtype"
                    onChange={this.onOtherOptionChange}
                    addonBefore="Other"
                    value={this.props.systems.siddhaOtherSubtype}
                  />
                </Checkbox.Group>
                {this.props.systems.siddhaSubtypes.length === 0 &&
                  this.props.systems.siddhaOtherSubtype.length === 0 &&
                  this.props.systems.isSubmit && (
                    <span className="form-error is-visible">Required</span>
                  )}
              </Col>
            </Row>
          )}
          {this.props.systems.systemType === "homeopathy" && (
            <Row>
              <label>Speciality</label>
              <Col span={8}>
                <Checkbox.Group
                  name="homeopathySubtypes"
                  style={{ width: "100%" }}
                  value={this.props.systems.homeopathySubtypes}
                  onChange={this.onHomeopathySubTypeChange}
                >
                  <Checkbox value="General Homeopathy">
                    General Homeopathy
                  </Checkbox>
                  <Input
                    placeholder="please specify"
                    name="homeopathyOtherSubtype"
                    onChange={this.onOtherOptionChange}
                    addonBefore="Other"
                    value={this.props.systems.homeopathyOtherSubtype}
                  />
                </Checkbox.Group>
                {this.props.systems.homeopathySubtypes.length === 0 &&
                  this.props.systems.homeopathyOtherSubtype.length === 0 &&
                  this.props.systems.isSubmit && (
                    <span className="form-error is-visible">Required</span>
                  )}
              </Col>
            </Row>
          )}
          {this.props.systems.systemType === "naturopathy" && (
            <Row>
              <label>Speciality</label>
              <Col span={8}>
                <Checkbox.Group
                  name="naturopathySubtypes"
                  style={{ width: "100%" }}
                  value={this.props.systems.naturopathySubtypes}
                  onChange={this.onNaturopathySubTypeChange}
                >
                  <Checkbox value="External Therapies with natural modalities">
                    External Therapies with natural modalities
                  </Checkbox>
                  <Checkbox value="Internal Therapies">
                    Internal Therapies
                  </Checkbox>
                  <Input
                    placeholder="please specify"
                    name="naturopathyOtherSubtype"
                    onChange={this.onOtherOptionChange}
                    addonBefore="Other"
                    value={this.props.systems.naturopathyOtherSubtype}
                  />
                </Checkbox.Group>
                {this.props.systems.naturopathySubtypes.length === 0 &&
                  this.props.systems.naturopathyOtherSubtype.length === 0 &&
                  this.props.systems.isSubmit && (
                    <span className="form-error is-visible">Required</span>
                  )}
              </Col>
            </Row>
          )}
          {this.props.systems.systemType === "yoga" && (
            <Row>
              <label>Speciality</label>
              <Col span={8}>
                <Checkbox.Group
                  name="yogaSubtypes"
                  style={{ width: "100%" }}
                  value={this.props.systems.yogaSubtypes}
                  onChange={this.onYogaSubTypeChange}
                >
                  <Checkbox value="Ashtang Yoga">Ashtang Yoga</Checkbox>
                  <Input
                    placeholder="please specify"
                    name="yogaOtherSubtype"
                    onChange={this.onOtherOptionChange}
                    addonBefore="Other"
                    value={this.props.systems.yogaOtherSubtype}
                    validations={[required]}
                  />
                </Checkbox.Group>
                {this.props.systems.yogaSubtypes.length === 0 &&
                  this.props.systems.yogaOtherSubtype.length === 0 &&
                  this.props.systems.isSubmit && (
                    <span className="form-error is-visible">Required</span>
                  )}
              </Col>
            </Row>
          )}
        </Form>
      </div>
    );
  }
}
export default Systems;
