import React, { Component } from "react";
import { Row, Col } from "antd";
import "./styles.css";

class AXTech extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    console.log(this.props);
  }

  render() {
    const {
      first,
      second,
      third,
      fourth,
      ipDepartment,
      opDepartment,
      systems,
      staffs
    } = this.props.state;
    const filteredStaffs = staffs.filter(x => x.isTechnical);

    let { annexureNo } = this.props;

    return (
      <div className="staff-table is-center">
        <Row className="is-center text-left">
          <Col offset={2} span={20}>
            <p className="text-center heading">ANNEXURE {annexureNo}</p>
          </Col>
          <Col span={20}>
            <p className="text-left">
              A list of technical staff (both technical and supervisory)
            </p>
          </Col>
        </Row>

        <Row className="is-center text-left">
          <Col offset={1} span={7} className="border-box">
            <p>Name</p>
          </Col>

          <Col span={8} className="border-box">
            <p>Qualification</p>
          </Col>

          <Col span={8} className="border-box">
            <p>Registration No.</p>
          </Col>
        </Row>
        {filteredStaffs.map(item => {
          return (
            <div>
              <Row className="is-center text-left">
                <Col offset={1} span={7} className="border-box">
                  <p className="answer">{item.name}</p>
                </Col>

                <Col span={8} className="border-box">
                  <p className="answer">{item.qualification}</p>
                </Col>

                <Col span={8} className="border-box">
                  <p className="answer">{item.regNumber}</p>
                </Col>
              </Row>
            </div>
          );
        })}
      </div>
    );
  }
}

export default AXTech;
