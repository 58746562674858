import React, { Component } from "react";
import { Row, Col } from "antd";
import "./styles.css";

class Tags1 extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    console.log(this.props);
  }

  render() {
    const {
      first,
      second,
      third,
      fourth,
      ipDepartment,
      opDepartment,
      systems,
      tags1
    } = this.props.state;
    let { annexureNo } = this.props;

    const generateTable = (type, list) => {
      return (
        <div className="question patient-table" style={{ marginTop: 20 }}>
          <Row className="is-center text-left">
            <Col offset={2} span={10}>
              <p className="question text-center border-box mb-0">No.</p>
            </Col>
            <Col span={10}>
              <p className="question text-center border-box mb-0">Speciality</p>
            </Col>
          </Row>
          <Row className="is-center text-left">
            {tags1.map((item, index) => {
              return (
                <React.Fragment>
                  <Col offset={2} span={10}>
                    <p className="answer text-center border-box mb-0">
                      {index + 1}
                    </p>
                  </Col>
                  <Col span={10}>
                    <p className="answer text-center border-box mb-0">
                      {item}
                    </p>
                  </Col>
                </React.Fragment>
              );
            })}
          </Row>
        </div>
      );
    };
    return (
      <div className="Tags1">
        <div>
          <Row className="is-center text-left">
            <Col offset={2} span={20}>
              <p className="text-center heading">ANNEXURE {annexureNo}</p>
            </Col>
            <Col offset={2} span={20}>
              <p className="text-left">Details of the equipments maintained:</p>
            </Col>
          </Row>
          {generateTable()}
        </div>
      </div>
    );
  }
}

export default Tags1;
