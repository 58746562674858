import React, { Component, Fragment } from 'react';
import {
	Layout,
	Col,
	Row,
	Radio,
	Button,
	InputNumber,
	Tag,
	Input,
	Tooltip,
	Icon
} from 'antd';

const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

class Others extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	render() {
		const radioStyle = {
			display: 'block',
			height: '30px',
			lineHeight: '30px'
		};
		const otherInputStyle = { width: 100, marginLeft: 10 };
		return (
			<div>
				<form className="firstForm">
					{this.props.deets.first.labAttachment === 'yes' ? (
						<h1>Tests & Equipments</h1>
					) : (
						<h1> Equipments</h1>
					)}
					{this.props.deets.first.labAttachment === 'yes' && (
						<React.Fragment key="tests">
							<label style={{marginTop: 30}}>Tests carried out in the Lab</label>
							<Row type="flex" className="heyFuckThis">
								{this.props.deets.tags.map((tag, index) => {
									const isLongTag = tag.length > 20;
									const tagElem = (
										<Tag
											key={tag}
											closable="true"
											afterClose={() => this.props.handleClose(tag)}
										>
											{isLongTag ? `${tag.slice(0, 20)}...` : tag}
										</Tag>
									);
									return isLongTag ? (
										<Tooltip title={tag} key={tag}>
											{tagElem}
										</Tooltip>
									) : (
										tagElem
									);
								})}
							</Row>

							<Input
								ref={this.props.saveInputRef}
								type="text"
								size="small"
								style={{ width: 200, marginTop: 20 }}
								value={this.props.deets.inputValue}
								onChange={this.props.handleInputChange}
								onBlur={this.props.handleInputConfirm}
								placeholder="enter a new test"
							/>
							<Button
								type="primary"
								onClick={this.props.handleInputConfirm}
								style={{ marginLeft: 10 }}
							>
								Add
							</Button>
							{
								(this.props.deets.tags.length === 0) &&
								<span className="form-error is-visible">Required</span>
							}
						</React.Fragment>
					)}

					<React.Fragment key="equipments">
						<label style={{ marginTop: 30 }}>
							Equipments in the {this.props.deets.first.estType}
						</label>
						<Row type="flex" className="heyFuckThis">
							{this.props.deets.tags1.map((tag, index) => {
								const isLongTag = tag.length > 20;
								const tagElem = (
									<Tag
										key={tag}
										closable="true"
										afterClose={() => this.props.handleClose1(tag)}
									>
										{isLongTag ? `${tag.slice(0, 20)}...` : tag}
									</Tag>
								);
								return isLongTag ? (
									<Tooltip title={tag} key={tag}>
										{tagElem}
									</Tooltip>
								) : (
									tagElem
								);
							})}
						</Row>

						<Input
							ref={this.props.saveInputRef1}
							type="text"
							size="small"
							style={{ width: 200, marginTop: 20 }}
							value={this.props.deets.inputValue1}
							onChange={this.props.handleInputChange1}
							onBlur={this.props.handleInputConfirm1}
							onPressEnter={this.props.handleInputConfirm1}
							placeholder="enter a new equipment"
						/>
						<Button
							type="primary"
							onClick={this.props.handleInputConfirm1}
							style={{ marginLeft: 10 }}
						>
							Add
						</Button>
						{
							(this.props.deets.tags1.length === 0)&&
							<span className="form-error is-visible">Required</span>
						}
					</React.Fragment>
				</form>
			</div>
		);
	}
}
export default Others;
