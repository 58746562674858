import React, { Component, Fragment } from 'react';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import { required } from './FormValidator';

import {
	Layout,
	Col,
	Row,
	Radio,
	Button,
	InputNumber,
	Tag,
	Tooltip,
	Icon,
	DatePicker
} from 'antd';
import moment from 'moment'
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

class Registration extends Component {
	constructor(props) {
		super(props);
		this.state = {

		};
	}

	render() {
		const radioStyle = {
			display: 'block',
			height: '30px',
			lineHeight: '30px'
		};
		const otherInputStyle = { width: 100, marginLeft: 10 };
		return (
			<div>
				<Form className="firstForm" ref={this.props.registration.form}>
					<h1>Registration Fees</h1>
					<React.Fragment key="regFees">
						<label>Payment Options</label>
						<div className="position-relative">
							<RadioGroup
								name="pymntOption"
								className="flexIt"
								onChange={this.props.update.bind(this)}
								value={this.props.registration.pymntOption}
							>
								<RadioButton style={radioStyle} value="Demand Draft">
									Demand Draft
							</RadioButton>
								<RadioButton style={radioStyle} value="Treasury Receipt">
									Treasury Receipt
							</RadioButton>
							</RadioGroup>
							{((this.props.registration.pymntOption === null) && this.props.registration.isSubmit) && (
								<span className="form-error is-visible">Required</span>
							)}
						</div>
						<Row>
							<Col span={8}>
								<label>Amount (in Rs.)</label>
								<div className="position-relative">
									<Input
										name="amount"
										disabled
										onChange={this.props.update.bind(this)}
										value={this.props.registration.amount}
									/>
								</div>
							</Col>
							<Col span={8}>
								<label>Details</label>
								<div className="position-relative">
									<Input
										name="details"
										validations={[required]}
										onChange={this.props.update.bind(this)}
										value={this.props.registration.details}
										placeholder="Bank/Branch/DD#"
									/>
								</div>
							</Col>
						</Row>
						<Row>
							<Col span={8}>
								<label>Place</label>
								<div className="position-relative">
									<Input
										name="place"
										validations={[required]}
										onChange={this.props.update.bind(this)}
										value={this.props.registration.place}
										placeholder="Enter a place"
									/>
								</div>
							</Col>
							<Col span={8}>
								<label>Date</label>
								<DatePicker
									size="large"
									placeholder='Pick a date'
									allowClear={false}
									value={moment(this.props.registration.date, 'DD/MM/YYYY')}
									onChange={this.props.onDateChange.bind(this)}
								/>
							</Col>
						</Row>
					</React.Fragment>

				</Form>
			</div>
		);
	}
}
export default Registration;
