import React from 'react';
import validator from 'validator';

export const required = (value, props) => {
    if (!value || (props.isCheckable && !props.checked)) {
        return <span className="form-error is-visible">Required</span>;
    }
};
export const IsDate = (value) => {
    if (!validator.isBefore(value)) {
            return <span className="form-error is-visible">Invalid Date</span>;

    }
    else{
        if(value.length !== 4)
            return <span className="form-error is-visible">Invalid Date</span>;

    }
};
export const IsMobileNumber = (value) => {
    //var patt = new RegExp("/\D*([+][9][1]|[9][1]|[0]){0,1}([6-9]{1})([0-9]{9})\D*/");
    //var res = patt.test(value);
    // /^(\+?91|0)?[6789]\d{9}$/
    // console.log(validator.matches(value, /^(\+?91|0)?[6789]\d{9}$/))
    if (!validator.isMobilePhone(value, 'en-IN')) {
        return <span className="form-error is-visible">Invalid Mobile Number</span>;

    }
    //console.log(value)
    //console.log(value.match(/^(\+\d{1,3}[- ]?)?\d{10}$/))
    // console.log(value.test(/^(\+?91|0)?[6789]\d{9}$/))
    // console.log(res)
}
export const email = (value) => {
    if (!validator.isEmail(value)) {
        return (<span className="form-error is-visible">{value} is not a valid email.</span>);
    }
};
export const isMin = (value) => {
    if (!validator.isInt(value, { min: 0 })) {
        return (<span className="form-error is-visible">{value} is not a valid.</span>);
    }
}
export const isEmpty = (value) => {
    if (!validator.isEmpty(value)) {
        return (<span className="form-error is-visible">{value} is not a valid.</span>);

    }
}
export const numeric = (value) => {
    if (!validator.isNumeric(value) && value.length !== 0) {
        return (<span className="form-error is-visible">{value} is not a valid.</span>);

    }
}

export const lt = (value, props) => {
    // get the maxLength from component's props
    if (value.toString().trim().length > props.maxLength) {
        // Return jsx
        return <span className="error">The value exceeded {props.maxLength} symbols.</span>
    }
};

const gt = (value, props) => {
    // get the maxLength from component's props
    if (value.toString().trim().length < props.maxLength) {
        // Return jsx
        return <span className="error">The value exceeded {props.maxLength} symbols.</span>
    }
};

export const password = (value, props, components) => {
    // NOTE: Tricky place. The 'value' argument is always current component's value.
    // So in case we're 'changing' let's say 'password' component - we'll compare it's value with 'confirm' value.
    // But if we're changing 'confirm' component - the condition will always be true
    // If we need to always compare own values - replace 'value' with components.password[0].value and make some magic with error rendering.
    if (value !== components['confirm'][0].value) { // components['password'][0].value !== components['confirm'][0].value
        // 'confirm' - name of input
        // components['confirm'] - array of same-name components because of checkboxes and radios
        return <span className="error">Passwords are not equal.</span>
    }
};

export const RadioButtonOther = (data, OthersValue) => {
    if (data === "Other") {
        return OthersValue.length !== 0;
    }
    return true;
}
export const RadioButtonIsEmpty = (data) => {
    return data.length === 0;
}
export const FirstFormValidation = (state) => {
    let errorMessage = ""
    // if (state.estMobile.length === 0 && state.estTele.length === 0) {
    //     errorMessage += "Enter Mobile Number or TelePhone Number ";
    // }
    if (!RadioButtonOther(state.estLocation, state.estotherLocation)) {
        errorMessage += "\n Enter the Location Value ";
    }
    if (state.estType === "" || state.labAttachment === "" || state.imagingAttachment === "" || state.bloodBankAttachment === "") {
        errorMessage += "\n Additional Deets";
    }
    return errorMessage;
    // if(RadioButtonIsEmpty(estLocation)){
    //     //errorMessage += "\n Enter the Location Value ";

    // }

}
export const SecondFormValidation = (state, estType, bloodBankAttachment, imagingAttachment, labAttachment) => {
    let errorMessage = "";
    if (estType === "centre") {
        if (state.centreSubType.length === 0 && state.otherCentreSubType.length === 0) {
            errorMessage += "Select a type"
        }
    }
    if (estType === "clinic") {
        console.log(state.clinicSubType)
        if (state.clinicSubType.length === 0 && state.otherClinicSubType.length === 0) {
            errorMessage += "Select a clinic"
        }
    }
    if (estType === "hospital") {
        if (state.hospitalSubType.length === 0 && state.otherHospitalSubType.length === 0) {
            errorMessage += "Select a type"
        }
    }
    if (bloodBankAttachment === 'yes') {
        if (state.bbFacilityType.length === 0 && state.otherBBFacilityInput.length === 0) {
            errorMessage += "Select a Blood Bank Attachment"
        }
    }
    if (imagingAttachment === 'yes') {
        if (state.imagingSubType.length === 0 && state.otherImagingSubType.length === 0) {
            errorMessage += "Select a Imaging Attachment"
        }
    }
    if (labAttachment === 'yes') {
        if (state.labSubType.length === 0 && state.otherLabSubType.length === 0) {
            errorMessage += "Select a lab Attachment"
        }
    }

    return errorMessage;

    // if(RadioButtonIsEmpty(estLocation)){
    //     //errorMessage += "\n Enter the Location Value ";

    // }

}

export const ThirdFormValidation = (state) => {
    if (state.estOwnership !== "") {
        if (state.estOwnership === "private") {
            if (state.estOwnrPrivate.length !== 0 || state.estOwnrPrivateOther.length !== 0) {
                return 1;
            }
        } else {
            if (state.estOwnrPublic.length !== 0 || state.estOwnrPublicOther.length !== 0) {
                return 1;
            }
        }
    }
    return 0;
}