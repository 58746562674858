import React from "react";
import ReactToPrint from "react-to-print";
import PropTypes from "prop-types";
import { Row, Col } from "antd";
import AdditionalOut from "./axOp";
import AdditionalIn from "./axIn";
import AdditionalHR from "./axHR";
import AxTech from "./axTech";
import AxSign from "./axSign";
import AxTags from "./axTags";
import AxTags1 from "./axTags1";
import "./styles.css";
import moment from "moment";
class ComponentToPrint extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      first,
      second,
      third,
      fourth,
      ipDepartment,
      opDepartment,
      systems,
      misc,
      registration,
      tags1
    } = this.props.state;

    const isChecked = condition => (condition ? <span>✔</span> : <span />);
    // const isChecked = condition => (condition ? `<span>✔</span>` : `<span />`);
    const renderPage1 = props => (
      <div>
        <Row className="is-center text-left">
          <Col offset={2} span={20}>
            <p className="text-center heading">FORM I</p>
            <p className="text-center heading">(see rule 7(1))</p>
            <p className="text-center underline heading">
              APPLICATION FORM FOR REGISTRATION / RENEWAL OF REGISTRATION OF
              CLINICAL ESTABLISHMENT
            </p>
            <p className="text-left underline">ESTABLISHMENT DETAILS</p>
          </Col>
        </Row>
        <div className="question-item">
          <Row className="is-center text-left">
            <Col offset={2} span={7}>
              <p className="question main-question">
                1. Name of Clinical Establishment:{" "}
              </p>
            </Col>
            <Col span={14} push={1}>
              <p className="answer">{first.estName}</p>
              {/* <p>{this.props.state.first.estName}</p> */}
            </Col>
          </Row>
          <Row className="is-center text-left">
            <Col offset={3} span={6}>
              <p className="question">1. Registration No: </p>
            </Col>
            <Col span={15} push={1}>
              <p className="answer"> {first.estRegNumber}</p>
              {/* <p> {this.props.state.first.estAddress}</p> */}
            </Col>
          </Row>
        </div>
        <div className="question-item">
          {/* Address Block */}
          <Row className="is-center text-left ">
            <Col offset={2} span={7}>
              <p className="question  main-question">2. Address: </p>
            </Col>
            <Col span={14} push={1}>
              <p className="answer">{first.estAddressL1}</p>
            </Col>
            <Col offset={2} span={7}>
              <p className="question">Village/Town: </p>
            </Col>
            <Col span={14} push={1}>
              <p className="answer"> {first.estLocality}</p>
            </Col>
            <Col offset={2} span={7}>
              <p className="question">Taluk:</p>
            </Col>
            <Col span={14} push={1}>
              <p className="answer"> {first.estTaluk}</p>
            </Col>
            {/* District & State Start */}

            <Col offset={2} span={8}>
              <p className="question">District:</p>
            </Col>
            <Col span={7}>
              <p className="answer "> {first.estDistrict}</p>
            </Col>

            <Col span={2}>
              <p className="question text-left">State:</p>
            </Col>
            <Col span={5}>
              <p className="answer text-left">
                {" "}
                {first.estState || "Tamil Nadu"}
              </p>
            </Col>

            {/* District & State END */}

            <Col offset={2} span={7}>
              <p className="question">Pincode:</p>
            </Col>
            <Col span={14} push={1}>
              <p className="answer"> {first.estPincode}</p>
            </Col>

            <Col offset={2} span={7}>
              <p className="question">Telephone No. (with STD code):</p>
            </Col>
            <Col span={14} push={1}>
              <p className="answer"> {first.estTele}</p>
            </Col>
          </Row>
          <Row className="is-center text-left">
            <Col offset={2} span={7}>
              <p className="question">Mobile:</p>
            </Col>
            <Col span={14} push={1}>
              <p className="answer"> {first.estMobile}</p>
            </Col>
          </Row>

          <Row className="is-center text-left">
            <Col offset={2} span={7}>
              <p className="question">Fax:</p>
            </Col>
            <Col span={14} push={1}>
              <p className="answer"> {first.estFax}</p>
            </Col>
          </Row>

          <Row className="is-center text-left">
            <Col offset={2} span={7}>
              <p className="question">Email ID:</p>
            </Col>
            <Col span={14} push={1}>
              <p className="answer"> {first.estEmail}</p>
            </Col>
          </Row>

          <Row className="is-center text-left">
            <Col offset={2} span={7}>
              <p className="question">Website (if any):</p>
            </Col>
            <Col span={14} push={1}>
              <p className="answer"> {first.estWebsite}</p>
            </Col>
          </Row>
        </div>
        <div className="question-item">
          {/* Year of starting */}
          <Row className="is-center text-left">
            <Col offset={2} span={7}>
              <p className="mb-0 question  main-question">
                3. Year of starting:{" "}
              </p>
            </Col>
            <Col span={14} push={1}>
              <p className="mb-0 answer"> {first.estdYear}</p>
            </Col>
            <Col offset={2} span={22}>
              <p className="text-left question" style={{ paddingLeft: "15px" }}>
                (From 4 to 11, Mark all whichever is applicable)
              </p>
            </Col>
          </Row>
        </div>
        <div className="question-item">
          {/* Location */}
          <Row className="is-center text-left page-break">
            <Col offset={2} span={22}>
              <p className="question  main-question">4. Location: </p>
            </Col>

            <Col
              offset={2}
              span={6}
              className="question"
              style={{ paddingLeft: "14px" }}
            >
              <p>Metro {isChecked(first.estLocation === "metro")}</p>
            </Col>
            <Col span={16} className="question">
              <p>State Capital {isChecked(first.estLocation === "capital")}</p>
            </Col>
            <Col
              offset={2}
              span={6}
              style={{ paddingLeft: "14px" }}
              className="question"
            >
              <p>City {isChecked(first.estLocation === "city")}</p>
            </Col>
            <Col span={16} className="question">
              <p>Town {isChecked(first.estLocation === "town")}</p>
            </Col>
            <Col
              offset={2}
              span={22}
              style={{ paddingLeft: "14px" }}
              className="question"
            >
              <p>Notified Area {isChecked(first.estLocation === "notified")}</p>
            </Col>
            <Col
              offset={2}
              span={22}
              style={{ paddingLeft: "14px" }}
              className="question"
            >
              <p>Village {isChecked(first.estLocation === "village")}</p>
            </Col>

            <Col
              offset={2}
              span={22}
              style={{ paddingLeft: "14px" }}
              className="question"
            >
              <p className="inline-block">Any other: </p>
              <p className="answer inline-block" style={{ marginLeft: "10px" }}>
                {" "}
                {first.estotherLocation}
              </p>
            </Col>
          </Row>
        </div>
      </div>
    );

    const picAddressL1 = third.picAddressL1;
    const picDistrict = third.picDistrict;
    const picEmail = third.picEmail;
    const picFax = third.picFax;
    const picLocality = third.picLocality;
    const picMobile = third.picMobile;
    const picName = third.picName;
    const picPincode = third.picPincode;
    const picTaluk = third.picTaluk;
    const picTele = third.picTele;
    const picDesignation = third.picDesignation;
    const picQual = third.picQual;

    let ownrAddressL1 = third.ownrAddressL1;
    let ownrDistrict = third.ownrDistrict;
    let ownrEmail = third.ownrEmail;
    let ownrFax = third.ownrFax;
    let ownrLocality = third.ownrLocality;
    let ownrMobile = third.ownrMobile;
    let ownrName = third.ownrName;
    let ownrPincode = third.ownrPincode;
    let ownrTaluk = third.ownrTaluk;
    let ownrTele = third.ownrTele;

    if (third.isOwner) {
      ownrAddressL1 = third.picAddressL1;
      ownrDistrict = third.picDistrict;
      ownrEmail = third.picEmail;
      ownrFax = third.picFax;
      ownrLocality = third.picLocality;
      ownrMobile = third.picMobile;
      ownrName = third.picName;
      ownrPincode = third.picPincode;
      ownrTaluk = third.picTaluk;
      ownrTele = third.picTele;
    }

    const renderPage2 = props => (
      <div>
        <Row className="is-center text-left">
          <Col offset={2} span={22}>
            <p className="mb-0 question">5. Ownership of Services </p>
          </Col>
          <Col offset={2} span={22}>
            <p className="mb-0 underline question">Public Sector</p>
          </Col>
        </Row>
        <Row className="is-center text-left question public-sector-table">
          <Col offset={2} span={6}>
            <p>
              Central Government {isChecked(second.estOwnrPublic === "capital")}
            </p>
          </Col>
          <Col span={8}>
            <p>
              State Government {isChecked(second.estOwnrPublic === "state")}{" "}
            </p>
          </Col>
          <Col span={8}>
            <p>
              Local Government {isChecked(second.estOwnrPublic === "local")}
            </p>
          </Col>
          <Col offset={2} span={6}>
            <p>
              Public Sector Undertaking{" "}
              {isChecked(second.estOwnrPublic === "psu")}
            </p>
          </Col>
          <Col span={8}>
            <p>Railways {isChecked(second.estOwnrPublic === "railways")}</p>
          </Col>
          <Col span={8}>
            <p>
              Employees State Insurance Corporation{" "}
              {isChecked(second.estOwnrPublic === "esic")}
            </p>
          </Col>
          <Col offset={2} span={6}>
            <p>
              Autonomous organization{" "}
              {isChecked(second.estOwnrPublic === "autonomous")}
            </p>
          </Col>
          <Col span={8}>
            <p>
              Society/Not for profit Companies{" "}
              {isChecked(second.estOwnrPublic === "society")}
            </p>
          </Col>
          <Col span={8}>
            <p className="inline-block">Any other (please specify)</p>
            <p className="inline-block answer">{second.estOwnrPublicOther}</p>
          </Col>
        </Row>
        <Row className="is-center text-left question private-sector-table">
          <Col offset={2} span={22}>
            <p className="underline">Private Sector</p>
          </Col>
          <Col offset={2} span={8} className="border-box">
            <p>
              Individual Proprietorship{" "}
              {isChecked(second.estOwnrPrivate === "proprietorship")}
            </p>
          </Col>
          <Col span={6} className="border-box">
            <p>
              Registered Partnership{" "}
              {isChecked(second.estOwnrPrivate === "partnership")}
            </p>
          </Col>
          <Col span={6} className="border-box">
            <p>
              Registered Company{" "}
              {isChecked(second.estOwnrPrivate === "company")}
            </p>
          </Col>
          <Col offset={2} span={20} className="border-box">
            <p className="inline-block">
              Corporation (including a society) registered under a Central,
              Provincial or State Act (Please specify){" "}
            </p>
            <p className="inline-block answer">{second.corporationSpec}</p>
          </Col>
          <Col offset={2} span={20} className="border-box">
            <p className="inline-block">
              Trust (including Charitable) registered under a Central, Provincial
              or State Act (please specify)
            </p>
            <p className="inline-block answer">{second.trustSpec}</p>
          </Col>
          <Col offset={2} span={20} className="border-box">
            <p className="inline-block">
              Branch of a Foreign Service provider (please specify)
            </p>
            <p className="inline-block answer">{second.foreignSpec}</p>
          </Col>
          <Col offset={2} span={20} className="border-box">
            <p className="inline-block">Any other (please specify)</p>
            <p className="inline-block answer">{second.estOwnrPrivateOther}</p>
          </Col>
        </Row>
        <div>
          <Row className="is-center text-left " style={{ marginTop: "10px" }}>
            <Col offset={2} span={6}>
              <p className="mb-0 question inline-block">
                6. Name of the owner of Clinical Establishment:
              </p>
            </Col>

            <Col span={16}>
              <p className="answer">{ownrName}</p>
            </Col>
          </Row>
          <Row className="is-center text-left ">
            <Col offset={3} span={2}>
              <p className="question">Address: </p>
            </Col>
            <Col span={19}>
              <p className="answer"> {ownrAddressL1}</p>
            </Col>
          </Row>
          <Row className="is-center text-left ">
            <Col offset={3} span={3}>
              <p className="question">Village/Town: </p>
            </Col>
            <Col span={4}>
              <p className="answer"> {ownrLocality}</p>
            </Col>
            <Col span={3}>
              <p className="question">Taluk: </p>
            </Col>
            <Col span={7}>
              <p className="answer"> {ownrTaluk}</p>
            </Col>
          </Row>
          <Row className="is-center text-left ">
            <Col offset={3} span={3}>
              <p className="question">District: </p>
            </Col>
            <Col span={4}>
              <p className="answer"> {ownrDistrict}</p>
            </Col>
            <Col span={3}>
              <p className="question">State: </p>
            </Col>
            <Col span={4}>
              <p className="answer">Tamil Nadu</p>
            </Col>
            <Col span={2}>
              <p className="question">Pincode: </p>
            </Col>
            <Col span={4}>
              <p className="answer"> {ownrPincode}</p>
            </Col>
          </Row>
          <Row className="is-center text-left ">
            <Col offset={3} span={4}>
              <p className="question">Telephone No. (with STD code): </p>
            </Col>
            <Col span={6}>
              <p className="answer"> {ownrTele}</p>
            </Col>
            <Col span={2}>
              <p className="question">Mobile: </p>
            </Col>
            <Col span={3}>
              <p className="answer"> {ownrMobile}</p>
            </Col>
          </Row>

          <Row className="is-center text-left ">
            <Col offset={3} span={4}>
              <p className="question">Email ID: </p>
            </Col>
            <Col span={6}>
              <p className="answer"> {ownrEmail}</p>
            </Col>
            <Col span={2}>
              <p className="question">Fax: </p>
            </Col>
            <Col span={3}>
              <p className="answer"> {ownrFax}</p>
            </Col>
          </Row>
        </div>
        {/* Question 7 */}
        <div>
          <Row className="is-center text-left question">
            <Col offset={2} span={12}>
              <p className="mb-0 ">
                7. Name, Designation and Qualification of person-in-charge of
                the clinical establishment
              </p>
            </Col>

            <Col span={10}>
              <p className="answer"> {picName}</p>
            </Col>
          </Row>

          <Row className="is-center text-left ">
            <Col offset={3} span={3}>
              <p className="question">Designation: </p>
            </Col>
            <Col span={4}>
              <p className="answer"> {picDesignation}</p>
            </Col>
            <Col span={3}>
              <p className="question">Qualification: </p>
            </Col>
            <Col span={7}>
              <p className="answer"> {picQual}</p>
            </Col>
          </Row>

          <Row className="is-center text-left ">
            <Col offset={3} span={2}>
              <p className="question">Address: </p>
            </Col>
            <Col span={19}>
              <p className="answer">{picAddressL1}</p>
            </Col>
          </Row>

          <Row className="is-center text-left ">
            <Col offset={3} span={3}>
              <p className="question">Village/Town: </p>
            </Col>
            <Col span={4}>
              <p className="answer"> {picLocality}</p>
            </Col>
            <Col span={3}>
              <p className="question">Taluk: </p>
            </Col>
            <Col span={7}>
              <p className="answer"> {picTaluk}</p>
            </Col>
          </Row>
          <Row className="is-center text-left ">
            <Col offset={3} span={3}>
              <p className="question">District: </p>
            </Col>
            <Col span={4}>
              <p className="answer"> {picDistrict}</p>
            </Col>
            <Col span={3}>
              <p className="question">State: </p>
            </Col>
            <Col span={4}>
              <p className="answer">Tamil Nadu</p>
            </Col>
            <Col span={2}>
              <p className="question">Pincode: </p>
            </Col>
            <Col span={4}>
              <p className="answer"> {picPincode}</p>
            </Col>
          </Row>
          <Row className="is-center text-left ">
            <Col offset={3} span={4}>
              <p className="question">Telephone No. (with STD code): </p>
            </Col>
            <Col span={6}>
              <p className="answer"> {picTele}</p>
            </Col>
            <Col span={2}>
              <p className="question">Mobile: </p>
            </Col>
            <Col span={3}>
              <p className="answer"> {picMobile}</p>
            </Col>
          </Row>

          <Row className="is-center text-left ">
            <Col offset={3} span={4}>
              <p className="question">Email: </p>
            </Col>
            <Col span={6}>
              <p className="answer"> {picEmail}</p>
            </Col>
            <Col span={2}>
              <p className="question">Fax: </p>
            </Col>
            <Col span={3}>
              <p className="answer"> {picFax}</p>
            </Col>
          </Row>
        </div>

        {/* Question 8 */}

        <Row className="is-center text-left">
          <Col offset={2} span={22}>
            <p className="mb-0 question">8. Any other (please specify)</p>
            <p className="answer" style={{ marginLeft: "10px" }} />
          </Col>
        </Row>
        {/* Question 9 */}

        <Row className="is-center text-left">
          <Col offset={2} span={22}>
            <p className="mb-0 question">
              9. Type of clinical establishment: (Please tick whichever is
              applicable)
            </p>
          </Col>
        </Row>

        <Row className="is-center text-left clinic-table">
          <Col offset={3} span={21}>
            <p className="bold underline inline-block question">Clinic</p>
            {first.estType.length && first.estType !== "clinic" ? (
              <span className="not-applicable inline-block ml-10 answer">
                Not Applicable
              </span>
            ) : null}
          </Col>

          <Col offset={2} span={7} className="border-box">
            <p>
              Single Practitioner{" "}
              {isChecked(fourth.clinicSubType.includes("single"))}
            </p>
          </Col>
          <Col span={6} className="border-box">
            <p>
              Consulting Room{" "}
              {isChecked(fourth.clinicSubType.includes("consulting"))}
            </p>
          </Col>
          <Col span={6} className="border-box">
            <p>
              Polyclinic{" "}
              {isChecked(fourth.clinicSubType.includes("polyclinic"))}
            </p>
          </Col>
          <Col offset={2} span={7} className="border-box">
            <p> </p>
          </Col>
          <Col span={6} className="border-box">
            <p>Dental {isChecked(fourth.clinicSubType.includes("dental"))}</p>
          </Col>
          <Col span={6} className="border-box">
            <p> </p>
          </Col>
          <Col offset={2} span={7} className="border-box">
            <p> Any other (Please specify) </p>
          </Col>
          <Col span={12} className="border-box">
            <p className="answer">{fourth.otherClinicSubType} </p>
          </Col>
        </Row>
      </div>
    );

    const renderPage3 = props => (
      <div>
        <div className="question ">
          {/* Centre Question Start */}
          <Row className="is-center text-left center-table">
            <Col offset={3} span={21}>
              <p className="bold underline inline-block question">Centre</p>
              {first.estType.length && first.estType !== "centre" ? (
                <span className="not-applicable inline-block ml-10 answer">
                  Not Applicable
                </span>
              ) : null}
            </Col>

            <Col offset={2} span={8} className="border-box">
              <p style={{ paddingBottom: "18px" }}>
                Primary Health Centre{" "}
                {isChecked(fourth.centreSubType.includes("phc"))}
              </p>
            </Col>
            <Col span={7} className="border-box">
              <p>
                Community Health Centre{" "}
                {isChecked(fourth.centreSubType.includes("chc"))}
              </p>
            </Col>
            <Col span={6} className="border-box">
              <p>
                Urban Health Centre{" "}
                {isChecked(fourth.centreSubType.includes("uhc"))}
              </p>
            </Col>
          </Row>
          <Row className="is-center text-left center-table">
            <Col offset={2} span={8} className="border-box">
              <p>
                Dispensary{" "}
                {isChecked(fourth.centreSubType.includes("dispensary"))}
              </p>
            </Col>
            <Col span={7} className="border-box">
              <p>
                Day Care Centre{" "}
                {isChecked(fourth.centreSubType.includes("daycare"))}
              </p>
            </Col>
            <Col span={6} className="border-box">
              <p>
                Counselling centre{" "}
                {isChecked(fourth.centreSubType.includes("counselling"))}
              </p>
            </Col>
          </Row>
          <Row className="is-center text-left center-table">
            <Col offset={2} span={8} className="border-box">
              <p>
                Physiotherapy Centre{" "}
                {isChecked(fourth.centreSubType.includes("physiotherapy"))}
              </p>
            </Col>
            <Col span={7} className="border-box">
              <p>
                Yoga Centre {isChecked(fourth.centreSubType.includes("yoga"))}
              </p>
            </Col>
            <Col span={6} className="border-box">
              <p>
                In Vitro Fertilization (IVF) Centre{" "}
                {isChecked(fourth.centreSubType.includes("ivf"))}
              </p>
            </Col>
          </Row>
          <Row className="is-center text-left center-table">
            <Col offset={2} span={8} className="border-box">
              <p>
                Dialysis {isChecked(fourth.centreSubType.includes("dialysis"))}
              </p>
            </Col>

            <Col span={7} className="border-box">
              <p>
                Hospice Centre{" "}
                {isChecked(fourth.centreSubType.includes("hospice"))}
              </p>
            </Col>
            <Col span={6} className="border-box">
              <p>
                Any other (like Audiometry, Prosthetic & orthotic etc. (please
                specify)
              </p>
              <p className="answer">{fourth.otherCentreSubType} </p>
            </Col>
          </Row>
        </div>
        {/* Centre Question End */}
        {/* Hospital Question Start */}
        <div>
          <Row className="is-center text-left hospital-table">
            <Col offset={3} span={21}>
              <p className="bold underline inline-block question">Hospital</p>
              {first.estType.length && first.estType !== "hospital" ? (
                <span className="not-applicable inline-block ml-10 answer">
                  Not Applicable
                </span>
              ) : null}
            </Col>
            <Col offset={2} span={11} className="border-box">
              <p className="question">
                General Practice Services{" "}
                {isChecked(
                  fourth.hospitalSubType.includes("general practice services")
                )}
              </p>
            </Col>
            <Col span={10} className="border-box">
              <p className="question">
                Maternity Home{" "}
                {isChecked(fourth.hospitalSubType.includes("maternity home"))}
              </p>
            </Col>
            <Col offset={2} span={11} className="border-box">
              <p className="question">
                Single speciality Services{" "}
                {isChecked(
                  fourth.hospitalSubType.includes("single speciality services")
                )}
              </p>
            </Col>
            <Col span={10} className="border-box">
              <p className="question">
                Multi Speciality Services{" "}
                {isChecked(
                  fourth.hospitalSubType.includes("multi speciality services")
                )}
              </p>
            </Col>{" "}
            <Col offset={2} span={11} className="border-box">
              <p className="question">
                Super speciality Services{" "}
                {isChecked(
                  fourth.hospitalSubType.includes("super  speciality services")
                )}
              </p>
            </Col>
            <Col span={10} className="border-box">
              <p className="question">
                Operation Theatre{" "}
                {isChecked(
                  fourth.hospitalSubType.includes("operation theatre")
                )}
              </p>
            </Col>{" "}
            <Col offset={2} span={11} className="border-box">
              <p className="question">
                Emergency Causality{" "}
                {isChecked(
                  fourth.hospitalSubType.includes("emergency casuality")
                )}
              </p>
            </Col>
            <Col span={10} className="border-box">
              <p className="question">
                Intensive Care Unit{" "}
                {isChecked(
                  fourth.hospitalSubType.includes("intensive care unit")
                )}
              </p>
            </Col>{" "}
            <Col offset={2} span={11} className="border-box">
              <p className="question">
                ICCU {isChecked(fourth.hospitalSubType.includes("ICCU"))}
              </p>
            </Col>
            <Col span={10} className="border-box">
              <p className="question inline-block">Any other please specify</p>
              <p className="answer inline-block ml-10">
                {fourth.otherHospitalSubType}
              </p>
            </Col>
          </Row>
        </div>
        {/* Hospital Question End */}
        {/* Question #10 */}
        <div className="lab-table">
          <Row className="is-center text-left">
            <Col offset={2} span={22}>
              <p className="question">10. Whether the clinical establishment</p>
            </Col>
            <Col offset={3} span={21}>
              <p className="question">
                (a) is attached with Laboratory (if so, please tick whichever is
                applicable)
              </p>
            </Col>
            <Col offset={2} span={8} className="border-box">
              <p className="question">
                Pathology {isChecked(fourth.labSubType.includes("pathology"))}
              </p>
            </Col>
            <Col span={7} className="border-box">
              <p className="question">
                Haematology{" "}
                {isChecked(fourth.labSubType.includes("haematology"))}
              </p>
            </Col>
            <Col span={6} className="border-box">
              <p className="question">
                Histopathology{" "}
                {isChecked(fourth.labSubType.includes("histopathology"))}
              </p>
            </Col>
          </Row>
          <Row className="is-center text-left">
            <Col offset={2} span={8} className="border-box">
              <p className="question">
                Cytology {isChecked(fourth.labSubType.includes("cytology"))}
              </p>
            </Col>
            <Col span={7} className="border-box">
              <p className="question">
                {" "}
                Genetics {isChecked(fourth.labSubType.includes("cytology"))}
              </p>
            </Col>
            <Col span={6} className="border-box">
              <p className="question">
                Samples Collection Centre{" "}
                {isChecked(
                  fourth.labSubType.includes("samples collection centre")
                )}
              </p>
            </Col>
          </Row>

          <Row className="is-center text-left">
            <Col offset={2} span={8} className="border-box">
              <p className="question">Any other (Please specify)</p>
            </Col>

            <Col span={13} className="border-box">
              <p className="answer">{fourth.otherLabSubType}</p>
            </Col>
          </Row>
          <Row className="is-center text-left">
            <Col offset={2} span={8} className="border-box">
              <p className="question">
                Biochemisty{" "}
                {isChecked(fourth.labSubType.includes("biochemistry"))}
              </p>
            </Col>

            <Col span={7} className="border-box">
              <p className="question">
                Microbiology{" "}
                {isChecked(fourth.labSubType.includes("microbiology"))}
              </p>
            </Col>
            <Col span={6} className="border-box">
              <p className="question">Any other (please specify)</p>
            </Col>
          </Row>
        </div>{" "}
        {/* Question #10 partially end */}
        {/* Question #10 a) Start */}
        <div style={{ marginTop: "10px" }} className="question">
          <Row className="is-center text-left">
            <Col offset={3} span={21}>
              <p className="mb-0">
                If answer to (a) above is yes, the following details may be
                furnished, namely :-
              </p>
            </Col>
            <Col offset={3} span={21}>
              <ul>
                <li>
                  Tests that it proposes to carry out:{" "}
                  <span className="answer">
                    {first.labAttachment === "yes" ? (
                      <span className="answer">REFER ANNEXURE NO: 5</span>
                    ) : null}{" "}
                  </span>
                </li>
                <li>
                  List of equipments available:{" "}
                  <span className="answer">REFER ANNEXURE NO: 4</span>
                </li>
                <li>
                  A list of technical staff (both technical and supervisory):{" "}
                  {first.labAttachment === "yes" ? (
                    <span className="answer">REFER ANNEXURE NO: 7</span>
                  ) : null}
                </li>
                <li>
                  List of personnel who are going to sign test reports:{" "}
                  {first.labAttachment === "yes" ? (
                    <span className="answer">REFER ANNEXURE NO: 6</span>
                  ) : null}
                </li>
              </ul>
            </Col>
          </Row>
        </div>
        {/* Question #10 a) End */}
        {/* Question #10 */}
        <div className="image-center-table question">
          <Row className="is-center text-left">
            <Col offset={3} span={21}>
              <p className="question">
                (b) is attached with Imaging Centre (if so, please tick
                whichever is applicable)
              </p>
            </Col>
            <Col offset={2} span={8} className="border-box">
              <p>
                Portable X Ray{" "}
                {isChecked(fourth.imagingSubType.includes("Portable x ray"))}
              </p>
            </Col>
            <Col span={7} className="border-box">
              <p>
                Conventional X Ray{" "}
                {isChecked(
                  fourth.imagingSubType.includes("conventional x ray")
                )}
              </p>
            </Col>
            <Col span={6} className="border-box">
              <p>
                Digital X Ray{" "}
                {isChecked(fourth.imagingSubType.includes("digital x ray"))}
              </p>
            </Col>
          </Row>
          <Row className="is-center text-left">
            <Col offset={2} span={8} className="border-box">
              <p>
                X Ray with computed Radiography system{" "}
                {isChecked(
                  fourth.imagingSubType.includes(
                    "x ray with computed radiography system"
                  )
                )}
              </p>
            </Col>
            <Col span={7} className="border-box">
              <p>
                Ultrasound{" "}
                {isChecked(fourth.imagingSubType.includes("ultra sound"))}
              </p>
            </Col>
            <Col span={6} className="border-box">
              <p>
                Ultra sound with Color Doppler{" "}
                {isChecked(
                  fourth.imagingSubType.includes(
                    "ultra sound with color doppler"
                  )
                )}
              </p>
            </Col>
          </Row>

          <Row className="is-center text-left">
            <Col offset={2} span={8} className="border-box">
              <p>
                Mammography{" "}
                {isChecked(fourth.imagingSubType.includes("mammography"))}
              </p>
            </Col>
            <Col span={7} className="border-box">
              <p>
                Orthopantomogram (OPG){" "}
                {isChecked(fourth.imagingSubType.includes("orthopantomogram"))}
              </p>
            </Col>
            <Col span={6} className="border-box">
              <p>
                CT Scan {isChecked(fourth.imagingSubType.includes("CT scan"))}
              </p>
            </Col>
          </Row>
          <Row className="is-center text-left">
            <Col offset={2} span={8} className="border-box">
              <p>
                Magnetic Resonance Imaging (MRI){" "}
                {isChecked(
                  fourth.imagingSubType.includes("magentic resonance imaging")
                )}
              </p>
            </Col>

            <Col span={7} className="border-box">
              <p>
                Positron Emission Tomography (PET) Scan{" "}
                {isChecked(
                  fourth.imagingSubType.includes("positron emission tomography")
                )}
              </p>
            </Col>
            <Col span={6} className="border-box">
              <p>
                Bone Densitometry{" "}
                {isChecked(fourth.imagingSubType.includes("bone densitometry"))}
              </p>
            </Col>
          </Row>
          <Row className="is-center text-left">
            <Col offset={2} span={8} className="border-box">
              <p>
                Uro-flowmetry{" "}
                {isChecked(fourth.imagingSubType.includes("uro-flowmetry"))}
              </p>
            </Col>

            <Col span={7} className="border-box">
              <p>Any other (Please specify)</p>
            </Col>
            <Col span={6} className="border-box">
              <p className="answer">{fourth.otherImagingSubType}</p>
            </Col>
          </Row>
        </div>
        {/* Question #10  c)*/}
        <div className="blood-bank-table question">
          <Row className="is-center text-left">
            <Col offset={3} span={21}>
              <p className="question">
                (c) is attached with Blood Banks (if so, please tick whichever
                is applicable)
              </p>
            </Col>
            <Col offset={2} span={8} className="border-box">
              <p>(A) Based on Location</p>
            </Col>
            <Col span={7} className="border-box">
              <p>
                <span />
              </p>
            </Col>
            <Col span={6} className="border-box">
              <p>
                <span />
              </p>
            </Col>
            <Col offset={2} span={8} className="border-box">
              <p>
                Standalone {isChecked(fourth.bbLocationType === "standalone")}
              </p>
            </Col>
            <Col span={7} className="border-box">
              <p>
                Hospital Based{" "}
                {isChecked(fourth.bbLocationType === "hospitalBased")}
              </p>
            </Col>
             {/* <Col span={6} className="border-box">
              Hospital Based{" "}
               <p className="answer">{fourth.otherBBLocationInput}</p>
             </Col> */}
            <Col span={6} className="border-box">
              <p>Any other (Please specify)</p>
            </Col>
            <Col span={6} offset={2} className="border-box">
              <p className="answer">{fourth.otherBBLocationInput}</p>
            </Col>
          </Row>
        </div>
        {/* Question #10  B)*/}
        <div className="facilities-table question">
          <Row className="is-center text-left">
            <Col offset={3} span={21}>
              <p className="question">(B) Based on Facilities</p>
            </Col>
            <Col offset={2} span={21} className="border-box">
              <p>
                Blood bank/Centre having whole blood facility only{" "}
                {isChecked(fourth.bbFacilityType === "only whole blood")}
              </p>
            </Col>
            <Col offset={2} span={21} className="border-box">
              <p>
                Blood bank/Centre having whole blood and component facility{" "}
                {isChecked(fourth.bbFacilityType === "whole blood n component")}
              </p>
            </Col>
            <Col offset={2} span={21} className="border-box">
              <p>
                Blood bank/Centre having whole blood and/or component facility
                with any other additional facility (please specify)
              </p>
              <p className="answer">{fourth.otherBBFacilityInput}</p>
            </Col>
          </Row>
        </div>
        {/* Question #11 */}
        <div>
          <Row className="is-center text-left">
            <Col offset={2} span={22}>
              <p className="question">
                11. Details of the equipments maintained with:
              </p>
            </Col>
            <Col offset={2} span={21}>
              {tags1.length && <p className="answer">REFER ANNEXURE NO: 4</p>}
            </Col>
          </Row>
        </div>
      </div>
    );

    let totalBed = 0;
    ipDepartment.map(item => {
      console.log(item);
      totalBed = totalBed + Number(item.numberOfBeds);
    });
    const generatePatientTable = (type, list) => {
      const numberLabel = type === "op" ? "Number of Rooms" : "Number of beds";
      const hiddenStyle = {};

      if (list.length > 3) {
        hiddenStyle.visibility = "hidden";
        hiddenStyle.diplay = "none";
      }

      let title = "";

      if (list.length > 3) {
        // title = (
        //   <p className="answer">
        //     REFER ANNEXURE NO: {type === "op" ? "3" : "2"}
        //   </p>
				// );
				title = null;
      } else if (type === "op") {
        title = <p>Total number of Out Patient Department Clinics</p>;
      } else {
        title = null;
      }

      if (list.length > 3) {
        return (
          <div>
            <Row className="is-center text-left">
              <Col offset={3} span={21}>
                {title}
              </Col>
            </Row>
            <div className="question patient-table">
              <Row className="is-center text-left">
                <Col offset={2} span={7}>
                  <p className="question text-center border-box mb-0">No.</p>
                </Col>
                <Col span={7}>
                  <p className="question text-center border-box mb-0">
                    Speciality
                  </p>
                </Col>
                <Col span={6}>
                  <p className="question text-center border-box mb-0">
                    {numberLabel}
                  </p>
                </Col>
              </Row>
              <Row className="is-center text-left">
                <Col offset={2} span={7}>
                  <p className="question text-center border-box mb-0">
                    <span className="answer">
										REFER ANNEXURE NO: {type === "op" ? "3" : "2"}
                    </span>
                  </p>
                </Col>
                <Col span={7}>
                  <p className="question text-center border-box mb-0">
                    <span />{" "}
                  </p>
                </Col>
                <Col span={6}>
                  <p className="question text-center border-box mb-0">
                    <span />{" "}
                  </p>
                </Col>
              </Row>
              <Row className="is-center text-left">
                <Col offset={2} span={7}>
                  <p className="question text-center border-box mb-0">
                    <span className="answer" />
                  </p>
                </Col>
                <Col span={7}>
                  <p className="question text-center border-box mb-0">
                    <span />{" "}
                  </p>
                </Col>
                <Col span={6}>
                  <p className="question text-center border-box mb-0">
                    <span />{" "}
                  </p>
                </Col>
              </Row>
              <Row className="is-center text-left">
                <Col offset={2} span={7}>
                  <p className="question text-center border-box mb-0">
                    <span className="answer" />
                  </p>
                </Col>
                <Col span={7}>
                  <p className="question text-center border-box mb-0">
                    <span />{" "}
                  </p>
                </Col>
                <Col span={6}>
                  <p className="question text-center border-box mb-0">
                    <span />{" "}
                  </p>
                </Col>
              </Row>
            </div>
          </div>
        );
      }

      return (
        <div>
          <Row className="is-center text-left">
            <Col offset={3} span={21}>
              {title}
            </Col>
          </Row>
          <div className="question patient-table" style={hiddenStyle}>
            <Row className="is-center text-left">
              <Col offset={2} span={7}>
                <p className="question text-center border-box mb-0">No.</p>
              </Col>
              <Col span={7}>
                <p className="question text-center border-box mb-0">
                  Speciality
                </p>
              </Col>
              <Col span={6}>
                <p className="question text-center border-box mb-0">
                  {numberLabel}
                </p>
              </Col>
            </Row>
            <Row className="is-center text-left" style={hiddenStyle}>
              {list.map((item, index) => {
                if (index > 2) {
                  return null;
                }
                return (
                  <React.Fragment key={index}>
                    <Col offset={2} span={7}>
                      <p className="question text-center border-box mb-0">
                        {index + 1}
                      </p>
                    </Col>
                    <Col span={7}>
                      <p className="question text-center border-box mb-0">
                        {item.speciality}
                      </p>
                    </Col>
                    <Col span={6}>
                      <p className="question text-center border-box mb-0">
                        {type === "op" ? item.numberOfRooms : item.numberOfBeds}
                      </p>
                    </Col>
                  </React.Fragment>
                );
              })}
            </Row>
          </div>
        </div>
      );
    };

    const renderPage4 = () => (
      <div className="text-left question">
        {/* Question #12 */}
        <div className="question">
          <Row className="is-center text-left allopathic-table">
            <Col offset={2} span={22}>
              <p>SYSTEM OF MEDICINE</p>
              <p className="question mb-0">
                12. Services offered (please tick whichever is applicable)
              </p>
            </Col>
            <Col offset={3} span={21}>
              <p className="question inline-block ">
                (a) <span className="underline">Allopathic</span>
              </p>{" "}
              <p className="question ">Speciality</p>{" "}
            </Col>
            <Col offset={2} span={5} className="border-box">
              <p className="question inline-block">
                Medical{" "}
                {isChecked(systems.allopathySubtypes.includes("Medical"))}
              </p>{" "}
            </Col>
            <Col span={5} className="border-box">
              <p className="question inline-block">
                Surgical{" "}
                {isChecked(systems.allopathySubtypes.includes("Surgical"))}
              </p>
            </Col>

            <Col span={5} className="border-box">
              <p className="question inline-block">
                Obstetrics and Gynecology{" "}
                {isChecked(
                  systems.allopathySubtypes.includes(
                    "Obstetrics and Gynecology"
                  )
                )}{" "}
              </p>{" "}
            </Col>
            <Col span={5} className="border-box">
              <p className="question inline-block">
                Paediatrics{" "}
                {isChecked(systems.allopathySubtypes.includes("Paediatrics"))}
              </p>{" "}
            </Col>

            <Col offset={2} span={5} className="border-box">
              <p className="question inline-block">Any other please specify</p>{" "}
            </Col>
            <Col span={15} className="border-box">
              <p className="answer inline-block">
                {systems.allopathyOtherSubtype}
              </p>{" "}
            </Col>
          </Row>

          <Row className="is-center text-left ayurveda-table">
            <Col offset={3} span={21}>
              <p className="question inline-block ">
                (b) <span className="underline">Ayurveda</span>
              </p>{" "}
            </Col>
            <Col offset={2} span={5} className="border-box">
              <p className="question inline-block">
                Anusadh Chikitsa{" "}
                {isChecked(
                  systems.ayurvedaSubtypes.includes("Anusadh Chikitsa")
                )}
              </p>{" "}
            </Col>
            <Col span={5} className="border-box">
              <p className="question inline-block">
                {" "}
                Shalya Chikitsa{" "}
                {isChecked(
                  systems.ayurvedaSubtypes.includes("Shalya Chikitsa")
                )}
              </p>{" "}
            </Col>
            <Col span={5} className="border-box">
              <p className="question inline-block">
                Shodhan Chikitsa{" "}
                {isChecked(
                  systems.ayurvedaSubtypes.includes("Shodhan Chikitsa")
                )}
              </p>{" "}
            </Col>

            <Col span={5} className="border-box">
              <p className="question inline-block">
                Rasayana{" "}
                {isChecked(systems.ayurvedaSubtypes.includes("Rasayana"))}
              </p>{" "}
            </Col>
            <Col span={5} offset={2} className="border-box">
              <p className="question inline-block">
                Pathya Vyavastha{" "}
                {isChecked(
                  systems.ayurvedaSubtypes.includes("Pathya Vyavastha")
                )}
              </p>{" "}
            </Col>

            <Col  span={5} className="border-box">
              <p className="question inline-block">Any other please specify</p>{" "}
            </Col>

            <Col span={10} className="border-box">
              <p className="answer inline-block">
                {systems.ayurvedaOtherSubtype}
              </p>{" "}
            </Col>
          </Row>

          {/* 12 c) */}
          <div className="unani-table">
            <Row className="is-center text-left ">
              <Col offset={3} span={21}>
                <p className="question inline-block ">
                  (c) <span className="underline">Unani</span>
                </p>{" "}
              </Col>
            </Row>
            <Row className="is-center text-left">
              <Col offset={2} span={5} className="border-box">
                <p className="question inline-block">
                  Matab {isChecked(systems.unaniSubtypes.includes("Matab"))}
                </p>{" "}
              </Col>
              <Col span={5} className="border-box">
                <p className="question inline-block">
                  Jarahat {isChecked(systems.unaniSubtypes.includes("Jarahat"))}
                </p>{" "}
              </Col>
              <Col span={5} className="border-box">
                <p className="question inline-block">
                  Ilaj-bit-Tadbeer{" "}
                  {isChecked(
                    systems.unaniSubtypes.includes("Ilaj-bit-Tadbeer")
                  )}
                </p>{" "}
              </Col>
              <Col span={5} className="border-box">
                <p className="question inline-block">
                  Hifzan-e-Sehat{" "}
                  {isChecked(systems.unaniSubtypes.includes("Hifzan-e-Sehat"))}
                </p>{" "}
              </Col>
            </Row>
            <Row className="is-center text-left">
              <Col offset={2} span={5} className="border-box">
                <p className="question inline-block">
                  Any other please specify
                </p>{" "}
              </Col>

              <Col span={15} className="border-box">
                <p className="answer inline-block">
                  {systems.unaniOtherSubtype}
                </p>{" "}
              </Col>
            </Row>
          </div>

          {/* 12 d) */}
          <div className="siddha-table">
            <Row className="is-center text-left">
              <Col offset={3} span={21}>
                <p className="question inline-block">
                  (c) <span className="underline">Siddha</span>
                </p>{" "}
              </Col>
              <Col offset={2} span={5} className="border-box">
                <p className="question inline-block">
                  Maruthuvam{" "}
                  {isChecked(systems.siddhaSubtypes.includes("Maruthuvam"))}
                </p>{" "}
              </Col>
              <Col span={5} className="border-box">
                <p className="question inline-block">
                  Sirappu Maruthuvam{" "}
                  {isChecked(
                    systems.siddhaSubtypes.includes("Sirappu Maruthuvam")
                  )}
                </p>{" "}
              </Col>
              <Col span={5} className="border-box">
                <p className="question inline-block">
                  Varmam Thokknam & Yoga{" "}
                  {isChecked(
                    systems.siddhaSubtypes.includes("Varmam Thokknam & Yoga")
                  )}
                </p>{" "}
              </Col>
              <Col span={5} className="border-box">
                <p className="answer inline-block">
                  <span />
                </p>{" "}
              </Col>
            </Row>
            <Row className="is-center text-left">
              <Col offset={2} span={5} className="border-box">
                <p className="question inline-block">
                  Any other please specify
                </p>{" "}
              </Col>

              <Col span={15} className="border-box">
                <p className="question inline-block">
                  {" "}
                  <span />{" "}
                </p>{" "}
              </Col>
            </Row>
          </div>

          {/* 12 e) */}
          <div className="homeopathy-table">
            <Row className="is-center text-left">
              <Col offset={3} span={21}>
                <p className="question inline-block ">
                  (e) <span className="underline">Homeopathy</span>
                </p>{" "}
              </Col>{" "}
              <Col offset={2} span={5} className="border-box">
                <p className="question inline-block">
                  General Homeopathy{" "}
                  {isChecked(
                    systems.homeopathySubtypes.includes("General Homeopathy")
                  )}
                </p>{" "}
              </Col>
              <Col span={15} className="border-box">
                <p className="answer inline-block">
                  {systems.homeopathyOtherSubtype}
                </p>{" "}
              </Col>
            </Row>
            <Row className="is-center text-left">
              <Col offset={2} span={5} className="border-box">
                <p className="question inline-block">
                  Any other please specify
                </p>{" "}
              </Col>
              <Col span={15} className="border-box">
                <p className="question inline-block">
                  {" "}
                  <span />{" "}
                </p>{" "}
              </Col>
            </Row>
          </div>

          {/* 12 f) */}
          <div className="neturopathy-table">
            <Row className="is-center text-left">
              <Col offset={3} span={21}>
                <p className="question inline-block">
                  (f) <span className="underline">Naturopathy</span>
                </p>{" "}
              </Col>{" "}
              <Col offset={2} span={5} className="border-box">
                <p className="question inline-block">
                  External Therapies natural modalities{" "}
                  {isChecked(
                    systems.naturopathyOtherSubtype.includes(
                      "Varmam Thokknam & Yoga"
                    )
                  )}
                </p>{" "}
              </Col>
              <Col span={15} className="border-box">
                <p className="question inline-block">
                  {" "}
                  Internal Therapies{" "}
                  {isChecked(
                    systems.naturopathyOtherSubtype.includes(
                      "Varmam Thokknam & Yoga"
                    )
                  )}
                </p>{" "}
              </Col>
            </Row>
            <Row className="is-center text-left">
              <Col offset={2} span={5} className="border-box">
                <p className="question inline-block">
                  Any other please specify
                </p>{" "}
              </Col>
              <Col span={15} className="border-box">
                <p className="answer inline-block">
                  {systems.naturopathyOtherSubtype}
                </p>{" "}
              </Col>
            </Row>
          </div>

          {/* 12 g) */}
          <Row className="is-center text-left yoga-table">
            <Col offset={3} span={21}>
              <p className="question inline-block">
                (g) <span className="underline">Yoga</span>
              </p>{" "}
            </Col>{" "}
            <Col offset={2} span={5} className="border-box">
              <p className="question inline-block">
                Ashtang Yoga{" "}
                {isChecked(systems.yogaSubtypes.includes("Ashtang Yoga"))}
              </p>{" "}
            </Col>
            <Col span={15} className="border-box">
              <p className="question inline-block">
                {" "}
                Any other please specify{" "}
              </p>{" "}
              <p className="answer inline-block">{systems.yogaOtherSubtype}</p>
            </Col>
          </Row>
        </div>

        {/* Question #13 */}
        <div>
          <Row className="is-center text-left">
            <Col offset={2} span={22}>
              <p className="question mb-0">
                13. Area of establishment (in square meters)
              </p>
            </Col>
            <Col offset={3} span={10}>
              <p className="question inline-block mb-0">a. Total area:</p>{" "}
              <span className="answer inline-block mb-0">
                {(misc.totalArea / 10.764).toFixed(2)} square meters
              </span>
            </Col>
            <Col span={10} pull={1}>
              <p className="question inline-block mb-0">
                (b) Construction Area:
              </p>{" "}
              <span className="answer inline-block mb-0">
                {(misc.constructedArea / 10.764).toFixed(2)} square meters
              </span>
            </Col>
          </Row>
        </div>
        {/* Question #14 */}
        <div>
          <Row className="is-center text-left">
            <Col offset={2} span={22}>
              <p className="question mb-0">14. Out-Patient Department</p>
            </Col>
          </Row>
          {generatePatientTable("op", opDepartment)}
        </div>

        {/* Question #15 */}

        <div>
          <Row className="is-center text-left">
            <Col offset={2} span={22}>
              <p className="question ">15. In-Patient Department</p>
            </Col>
            <Col offset={3} span={10}>
              <p className="question">
                a. Total number of beds:{" "}
                <span className="answer">{totalBed}</span>
              </p>
            </Col>
            <Col span={10} pull={1}>
              <p className="question ">
                Speciality-wise distribution of beds, please specify:
              </p>
            </Col>
          </Row>
          {generatePatientTable("ip", ipDepartment)}
        </div>
      </div>
    );
    const renderPage5 = () => (
      <div className="question">
        {/* Question #16 */}
        <div>
          <Row className="is-center text-left">
            <Col offset={2} span={22}>
              <p className="question">16. Biomedical Waste Management</p>
            </Col>
            <Col offset={3} span={21}>
              <p className="question">
                a. Method of treatment and/or disposal of bio-medical waste
              </p>
            </Col>
          </Row>
          <Row className="is-center text-left">
            <Col offset={4} span={10}>
              <p className="question">
                Through Common Facility{" "}
                {isChecked(misc.disposal === "Through common facility")}
              </p>
            </Col>
            <Col span={10}>
              <p className="question">
                Onsite Facility {isChecked(misc.disposal === "Onsite")}
              </p>
            </Col>
          </Row>
          <Row className="is-center text-left">
            <Col offset={4} span={10}>
              <p className="question">Any other (please specify)</p>
            </Col>
            <Col span={10}>
              <p className="answer">{misc.disposalOther}</p>
            </Col>
          </Row>

          <Row className="is-center text-left">
            <Col offset={3} span={21}>
              <p className="question">
                b. Whether authorization from Pollution Control Board/Pollution
                Control Committee obtained?
              </p>
            </Col>
          </Row>
          <Row className="is-center text-left">
            <Col offset={4} span={5}>
              <p className="question">
                Yes {isChecked(misc.authorization === "yes")}
              </p>
            </Col>

            <Col span={5}>
              <p className="question">
                No {isChecked(misc.authorization === "no")}
              </p>
            </Col>

            <Col span={5}>
              <p className="question">
                Applied for {isChecked(misc.authorization === "applied for")}
              </p>
            </Col>

            <Col span={5}>
              <p className="question">
                Not applicable{" "}
                {isChecked(misc.authorization === "not applicable")}
              </p>
            </Col>
          </Row>
        </div>

        {/* Question #17 */}
        <div className="staff-table">
          <Row className="is-center text-left">
            <Col offset={2} span={22}>
              <p className="question">
                17. Total number of Staff (as on date of application)
              </p>
            </Col>
            <Col offset={3} span={10}>
              <p className="question inline-block">
                Number of permanent staff:
              </p>{" "}
              <span className="answer inline-block" />
            </Col>
            <Col span={10} pull={1}>
              <p className="question inline-block">
                Number of temporary staff:
              </p>{" "}
              <span className="answer inline-block" />
            </Col>
          </Row>
          <Row className="is-center text-left">
            <Col offset={1} span={4} className="border-box">
              <p>Category of Staff</p>
            </Col>

            <Col span={2} className="border-box">
              <p>Name</p>
            </Col>

            <Col span={4} className="border-box">
              <p>Qualification</p>
            </Col>

            <Col span={5} className="border-box">
              <p>Registration Number</p>
            </Col>

            <Col span={7} className="border-box">
              <p>Nature of Service: Temporary/Permanent</p>
            </Col>
          </Row>
          <Row className="is-center text-left">
            <Col offset={1} span={4} className="border-box">
              <p>Doctors</p>
            </Col>

            <Col span={18} className="border-box">
              <p className="answer">REFER ANNEXURE NO: 1</p>
            </Col>
          </Row>
          <Row className="is-center text-left">
            <Col offset={1} span={4} className="border-box">
              <p>Nursing Staff</p>
            </Col>

            <Col span={18} className="border-box">
              <p>
                <span />
              </p>
            </Col>
          </Row>

          <Row className="is-center text-left">
            <Col offset={1} span={4} className="border-box">
              <p>Para-medical Staff</p>
            </Col>

            <Col span={18} className="border-box">
              <p>
                <span />
              </p>
            </Col>
          </Row>
          <Row className="is-center text-left">
            <Col offset={1} span={4} className="border-box">
              <p>Paramacists</p>
            </Col>

            <Col span={18} className="border-box">
              <p>
                <span />
              </p>
            </Col>
          </Row>
          <Row className="is-center text-left">
            <Col offset={1} span={4} className="border-box">
              <p>Support Staff</p>
            </Col>

            <Col span={18} className="border-box">
              <p>
                <span />
              </p>
            </Col>
          </Row>

          <Row className="is-center text-left">
            <Col offset={1} span={4} className="border-box">
              <p>Others Please Specify</p>
            </Col>

            <Col span={18} className="border-box">
              <p>
                <span />
              </p>
            </Col>
            <Col offset={1}>
              <p>Seperate annexure may be attached</p>
            </Col>
          </Row>
        </div>
        {/* Question #18 */}
        <div>
          <Row className="is-center text-left">
            <Col offset={2} span={22}>
              <p className="question inline-block">
                18. Payment options for Registration Fees:
              </p>
              <p className="inline-block" style={{ marginLeft: "15px" }}>
                Demand Draft{" "}
                {isChecked(registration.pymntOption === "Demand Draft")}
              </p>
              <p className="inline-block" style={{ marginLeft: "15px" }}>
                Treasury receipt{" "}
                {isChecked(registration.pymntOption === "Treasury Receipt")}
              </p>
            </Col>
            <Col offset={3} span={21}>
              <p className="question inline-block">Amount (in Rs.)</p>{" "}
              <span className="answer inline-block">
                ₹ {registration.amount}
              </span>
            </Col>
            <Col offset={3} span={21}>
              <p className="question inline-block">Details:</p>{" "}
              <span className="answer inline-block">
                {registration.details}
              </span>
            </Col>
          </Row>
          <Row className="is-center text-left">
            <Col offset={3} span={21}>
              <p className="text-left" style={{ paddingBottom: "20px" }}>
                I/We hearby declare that the statement stated abive are true and
                correct to the dest of my/our knowledge and I/We shall abide by
                the Tamil Nadu Clinical Establishments (Regulation) Act, 1997
                and the Rules made thereunder
              </p>
            </Col>
            <Col offset={3} span={21}>
              <p className="question inline-block">Place:</p>{" "}
              <p className="answer inline-block">{registration.place}</p>
            </Col>
            <Col offset={3} span={10}>
              <p className="question inline-block">Date:</p>{" "}
              {/* <p className="answer inline-block">{moment(registration.date, 'DD/MM/YYYY')}</p> */}
              <p className="answer inline-block">
                {moment(registration.date).format("DD/MM/YYYY")}
              </p>
            </Col>
            <Col span={10} pull={1}>
              <p className="question inline-block">
                Signature of the Authorised person of the clinical establishment
              </p>{" "}
            </Col>
          </Row>

          <Row className="is-center text-left">
            <Col offset={2} span={20} style={{ marginTop: "10px" }}>
              <hr style={{ marginTop: "30px" }} />
            </Col>

            <Col offset={2} span={20} style={{ marginTop: "10px" }}>
              <p
                className="underline text-center"
                style={{ marginTop: "10px" }}
              >
                Acknowledgment
              </p>
              <p>
                Received Application for Registration from
                ................................................
              </p>
            </Col>
            <Col offset={12} span={11} style={{ marginTop: "10px" }}>
              <p className="text-right">Signature of the Receiving Officer</p>
              <p className="text-left">Name:</p>
              <p className="text-left">Date with Seal:</p>
            </Col>
          </Row>
        </div>
      </div>
    );

    const renderPage6 = () => <AdditionalHR {...this.props} annexureNo="1" />;
    // Seems like below component name is wrong.
    const renderPage7 = () =>
      opDepartment.length > 3 ? (
        <AdditionalOut {...this.props} annexureNo="2" />
      ) : null;
    const renderPage8 = () =>
      ipDepartment.length > 3 ? (
        <AdditionalIn {...this.props} annexureNo="3" />
      ) : null;
    const renderPage9 = () => <AxTags1 {...this.props} annexureNo="4" />;

    const renderPage10 = () =>
      first.labAttachment === "yes" ? (
        <AxTags {...this.props} annexureNo="5" />
      ) : null;
    const renderPage11 = () =>
      first.labAttachment === "yes" ? (
        <AxSign {...this.props} annexureNo="6" />
      ) : null;
    const renderPage12 = () =>
      first.labAttachment === "yes" ? (
        <AxTech {...this.props} annexureNo="7" />
      ) : null;

    return (
      // <div className="pdf-page">
      //   {renderPage1()}
      //   {renderPage2()}
      //   {renderPage3()}
      //   {renderPage4()}
      //   {renderPage5()}
      // </div>
      <div>
        <div className="single-page page1">{renderPage1()}</div>
        <div className="single-page page2">{renderPage2()}</div>
        <div className="single-page page3">{renderPage3()}</div>
        <div className="single-page page4">{renderPage4()}</div>
        <div className="single-page page5">{renderPage5()}</div>
        <div className="single-page page-annex page-break ">
          {renderPage6()}
        </div>
        <div className="single-page page-annex page-break ">
          {renderPage7()}
        </div>

        <div className="single-page page-annex page-break ">
          {renderPage8()}
        </div>
        <div className="single-page page-annex page-break ">
          {renderPage9()}
        </div>
        <div className="single-page page-annex page-break ">
          {renderPage10()}
        </div>
        <div className="single-page page-annex page-break ">
          {renderPage11()}
        </div>
        <div className="single-page page-annex page-break ">
          {renderPage12()}
        </div>
      </div>
    );
  }
}

class Example extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    console.log(this.props);
    return (
      <div>
        <ReactToPrint
          trigger={() => this.props.trigger}
          content={() => this.componentRef}
        />
        <ComponentToPrint
          ref={el => (this.componentRef = el)}
          {...this.props}
        />
      </div>
    );
  }
}

export default Example;
