import { Checkbox, Col, Divider, Row } from 'antd';
import React, { Component } from 'react';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import { IsMobileNumber, required } from './FormValidator';

class Third extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	onChange = e => {
		const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
		const name = e.target.name;

		this.setState({ [name]: value });
		this.props.update(name, value);
	};

	render() {
		return (
			<div>
				<Form ref={this.props.propsFromIndex.form} className="firstForm">
					<h1>Person in-charge & Ownership</h1>
					<React.Fragment>
						<Row>
							<Divider orientation="left">Details of Person-in-charge of the establishment</Divider>
						</Row>
						<Row>
							<Col span={12}>
								<label>Name</label>
								<Input
									validations={[required]}
									type="text"
									name="picName"
									value={this.props.propsFromIndex.picName}
									onChange={this.onChange}
								/>
							</Col>
							<Col span={12}>
								<label>Qualification</label>
								<Input
									validations={[required]}
									type="text"
									name="picQual"
									value={this.props.propsFromIndex.picQual}
									onChange={this.onChange}
								/>
							</Col>
						</Row>
						<Row>
							<Col span={12}>
								<label>Designation</label>
								<Input
									validations={[required]}
									type="text"
									name="picDesignation"
									value={this.props.propsFromIndex.picDesignation}
									onChange={this.onChange}
								/>
							</Col>
						</Row>
						<Row>
							<Col span={12}>
								<label>Door No. & Street</label>
								<Input
									validations={[required]}
									type="text"
									name="picAddressL1"
									value={this.props.propsFromIndex.picAddressL1}
									onChange={this.onChange}
								/>
							</Col>
							<Col span={12}>
								<label>Village/Town</label>
								<Input
									validations={[required]}
									type="text"
									name="picLocality"
									value={this.props.propsFromIndex.picLocality}
									onChange={this.onChange}
								/>
							</Col>
						</Row>
						<Row>
							<Col span={12}>
								<label>Taluk</label>
								<Input
									type="text"
									name="picTaluk"
									value={this.props.propsFromIndex.picTaluk}
									onChange={this.onChange}
								/>
							</Col>
							<Col span={12}>
								<label>District</label>
								<Input
									validations={[required]}

									type="text"
									name="picDistrict"
									value={this.props.propsFromIndex.picDistrict}
									onChange={this.onChange}
								/>
							</Col>
						</Row>
						<Row>
							<Col span={12}>
								<label>Pincode</label>
								<Input
									validations={[required]}

									type="text"
									name="picPincode"
									value={this.props.propsFromIndex.picPincode}
									onChange={this.onChange}
								/>
							</Col>
							<Col span={12}>
								<label>Fax</label>
								<Input

									type="text"
									name="picFax"
									value={this.props.propsFromIndex.picFax}
									onChange={this.onChange}
								/>
							</Col>
						</Row>
						<Row>
							<Col span={12}>
								<label>Telephone No. (with STD code)</label>
								<Input
									validations={[required]}

									type="text"
									name="picTele"
									value={this.props.propsFromIndex.picTele}
									onChange={this.onChange}
								/>
							</Col>
							<Col span={12}>
								<label>Mobile </label>
								<Input
									validations={[required, IsMobileNumber]}

									type="text"
									name="picMobile"
									value={this.props.propsFromIndex.picMobile}
									onChange={this.onChange}
								/>
							</Col>
						</Row>
						<Row>
							<Col span={12}>
								<label>Email ID</label>
								<Input
									validations={[required]}

									type="text"
									name="picEmail"
									value={this.props.propsFromIndex.picEmail}
									onChange={this.onChange}
								/>
							</Col>
						</Row>
						<Row style={{ marginTop: 20 }}>
							<Col span={12}>
								<Checkbox
									name="isOwner"
									checked={this.props.propsFromIndex.isOwner}
									onChange={this.onChange}>Person in-charge is also the Owner</Checkbox>
							</Col>
						</Row>
					</React.Fragment>
					{/* ***********************
						When the above is not an owner
						*************************** */}
					{this.props.propsFromIndex.isOwner === false &&
						<React.Fragment>
							<Row style={{ marginTop: 20 }}>
								<Divider orientation="left">Details of owner of the establishment</Divider>
							</Row>
							<Row>
								<Col span={12}>
									<label>Name</label>
									<Input
										validations={[required]}

										type="text"
										name="ownrName"
										value={this.props.propsFromIndex.ownrName}
										onChange={this.onChange}
									/>
								</Col>
								<Col span={12}>
									<label>Email ID</label>
									<Input
										validations={[required]}

										type="text"
										name="ownrEmail"
										value={this.props.propsFromIndex.ownrEmail}
										onChange={this.onChange}
									/>
								</Col>
							</Row>
							<Row>
								<Col span={12}>
									<label>Door No. & Street</label>
									<Input
										validations={[required]}

										type="text"
										name="ownrAddressL1"
										value={this.props.propsFromIndex.ownrAddressL1}
										onChange={this.onChange}
									/>
								</Col>
								<Col span={12}>
									<label>Village/Town</label>
									<Input
										validations={[required]}

										type="text"
										name="ownrLocality"
										value={this.props.propsFromIndex.ownrLocality}
										onChange={this.onChange}
									/>
								</Col>
							</Row>
							<Row>
								<Col span={12}>
									<label>Taluk</label>
									<Input
										validations={[required]}

										type="text"
										name="ownrTaluk"
										value={this.props.propsFromIndex.ownrTaluk}
										onChange={this.onChange}
									/>
								</Col>
								<Col span={12}>
									<label>District</label>
									<Input
										validations={[required]}

										type="text"
										name="ownrDistrict"
										value={this.props.propsFromIndex.ownrDistrict}
										onChange={this.onChange}
									/>
								</Col>
							</Row>
							<Row>
								<Col span={12}>
									<label>Pincode</label>
									<Input
										validations={[required]}

										type="text"
										name="ownrPincode"
										value={this.props.propsFromIndex.ownrPincode}
										onChange={this.onChange}
									/>
								</Col>
								<Col span={12}>
									<label>Fax</label>
									<Input
										validations={[required]}

										type="text"
										name="ownrFax"
										value={this.props.propsFromIndex.ownrFax}
										onChange={this.onChange}
									/>
								</Col>
							</Row>
							<Row>
								<Col span={12}>
									<label>Telephone No. (with STD code)</label>
									<Input
										validations={[required]}

										type="text"
										name="ownrTele"
										value={this.props.propsFromIndex.ownrTele}
										onChange={this.onChange}
									/>
								</Col>
								<Col span={12}>
									<label>Mobile </label>
									<Input
										validations={[required]}

										type="text"
										name="ownrMobile"
										value={this.props.propsFromIndex.ownrMobile}
										onChange={this.onChange}
									/>
								</Col>
							</Row>
							{/* <Row>
							<Col span={12}>
								<label>Email ID</label>
								<input
									type="text"
									name="ownrEmail"
									value={this.props.propsFromIndex.ownrEmail}
									onChange={this.onChange}
								/>
							</Col>
						</Row>
						// TODO: redefine field priorities & realign
						*/}
						</React.Fragment>
					}
				</Form>
			</div>

		);
	}
}
export default Third;
