import { Col, Divider, Radio, Row } from "antd";
import React, { Component } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import { IsDate, IsMobileNumber, numeric, required } from "./FormValidator";
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

class First extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onChange = e => {
    const target = e.target;
    const value = target.value;
    this.setState({ [target.name]: value });
    this.props.update(target.name, value);
  };
  onRadioChange = e => {
    console.log(e.target.name, e.target.value);
    this.props.update(e.target.name, e.target.value);
  };
  onOtherOptionChange = e => {
    this.props.update(e.target.name, e.target.value);
  };
  onFormRef = f => {
    console.log(f);
    //this.props.update('form', f)
  };

  render() {
    const radioStyle = {
      display: "block",
      height: "30px",
      lineHeight: "30px"
    };
    const otherInputStyle = {
      width: 180,
      marginLeft: 20,
      marginRight: 20
    };
    return (
      <div>
        <Form ref={this.props.propsFromIndex.form} className="firstForm">
          <h1>Establishment details</h1>
          <Row>
            <Col span={12}>
              <label>Name of the Clinical Establishment</label>
              <div>
                <Input
                  type="text"
                  name="estName"
                  value={this.props.propsFromIndex.estName}
                  onChange={this.onChange}
                  validations={[required]}
                />
              </div>
            </Col>
            <Col span={12}>
              <label>Registration Number (for renewals only)</label>
              <Input
                type="text"
                name="estRegNumber"
                value={this.props.propsFromIndex.estRegNumber}
                onChange={this.onChange}
              />
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <label>Year of starting</label>
              <Input
                type="text"
                name="estdYear"
                value={this.props.propsFromIndex.estdYear}
                onChange={this.onChange}
                validations={[required, IsDate]}
              />
            </Col>
          </Row>
          <Row>
            <Divider orientation="left">Doctor's Information</Divider>
          </Row>
          <Row>
            <Col span={12}>
              <label>Name of the doctor</label>
              <Input
                type="text"
                name="docName"
                validations={[required]}
                value={this.props.propsFromIndex.docName}
                onChange={this.onChange}
              />
            </Col>
            <Col span={12}>
              <label>Doctor's Mobile</label>
              <Input
                type="text"
                name="docMobile"
                value={this.props.propsFromIndex.docMobile}
                onChange={this.onChange}
                validations={[required, IsMobileNumber]}
              />
            </Col>
            <Col span={12}>
              <label>Doctor's Email</label>
              <Input
                type="email"
                name="docEmail"
                validations={[required]}
                value={this.props.propsFromIndex.docEmail}
                onChange={this.onChange}
              />
            </Col>
          </Row>
          <Row>
            <Divider orientation="left">Address</Divider>
          </Row>
          <Row>
            <Col span={12}>
              <label>Door No. & Street</label>
              <Input
                type="text"
                name="estAddressL1"
                value={this.props.propsFromIndex.estAddressL1}
                onChange={this.onChange}
                validations={[required]}
              />
            </Col>
            <Col span={12}>
              <label>Village/Town</label>
              <Input
                type="text"
                name="estLocality"
                value={this.props.propsFromIndex.estLocality}
                onChange={this.onChange}
                validations={[required]}
              />
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <label>Taluk</label>
              <input
                type="text"
                name="estTaluk"
                value={this.props.propsFromIndex.estTaluk}
                onChange={this.onChange}
              />
            </Col>
            <Col span={12}>
              <label>District</label>
              <Input
                type="text"
                name="estDistrict"
                value={this.props.propsFromIndex.estDistrict}
                onChange={this.onChange}
                validations={[required]}
              />
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <label>Pincode</label>
              <Input
                type="text"
                name="estPincode"
                validations={[required]}
                value={this.props.propsFromIndex.estPincode}
                onChange={this.onChange}
              />
            </Col>
            <Col span={12}>
              <label>Fax</label>
              <Input
                type="text"
                name="estFax"
                value={this.props.propsFromIndex.estFax}
                validations={[numeric]}
                onChange={this.onChange}
              />
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <label>Telephone No. (with STD code)</label>
              <Input
                type="text"
                name="estTele"
                validations={[required]}
                value={this.props.propsFromIndex.estTele}
                onChange={this.onChange}
              />
            </Col>
            <Col span={12}>
              <label>Mobile </label>
              <Input
                type="text"
                name="estMobile"
                value={this.props.propsFromIndex.estMobile}
                onChange={this.onChange}
                validations={[required, IsMobileNumber]}
              />
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <label>Email ID</label>
              <Input
                type="text"
                name="estEmail"
                required
                value={this.props.propsFromIndex.estEmail}
                onChange={this.onChange}
                validations={[required]}
              />
            </Col>
            <Col span={12}>
              <label>Website </label>
              <input
                type="text"
                name="estWebsite"
                value={this.props.propsFromIndex.estWebsite}
                onChange={this.onChange}
              />
            </Col>
          </Row>
          <Row>
            <label>Location</label>
            <Col span={12}>
              <RadioGroup
                required
                validations={[required]}
                name="estLocation"
                onChange={this.onRadioChange}
                value={this.props.propsFromIndex.estLocation}
              >
                <Col span={16}>
                  <Radio style={radioStyle} value="metro">
                    Metro
                  </Radio>
                  <Radio style={radioStyle} value="capital">
                    State Capital
                  </Radio>
                  <Radio style={radioStyle} value="city">
                    City
                  </Radio>
                  <Radio style={radioStyle} value="other">
                    Other
                    {this.props.propsFromIndex.estLocation === "other" ? (
                      <input
                        name="estotherLocation"
                        onChange={this.onOtherOptionChange}
                        value={this.props.propsFromIndex.estotherLocation}
                        style={otherInputStyle}
                      />
                    ) : (
                      <input style={otherInputStyle} disabled />
                    )}
                  </Radio>
                </Col>
                <Col span={8}>
                  <Radio style={radioStyle} value="town">
                    Town
                  </Radio>
                  <Radio style={radioStyle} value="notified">
                    Notified Area
                  </Radio>
                  <Radio style={radioStyle} value="village">
                    Village
                  </Radio>
                </Col>
              </RadioGroup>
              {(this.props.propsFromIndex.estLocation === "" ||
                this.props.propsFromIndex.estLocation === "other") &&
                this.props.propsFromIndex.estotherLocation.length === 0 &&
                this.props.propsFromIndex.isSubmit && (
                  <span className="form-error is-visible">Required</span>
                )}
            </Col>
          </Row>
          <Row>
            <Divider orientation="left">Additional Details</Divider>
          </Row>
          <Row>
            <Col span={5}>
              <label>Type of Establishment</label>
            </Col>
            <Col span={7}>
              <RadioGroup
                className="flexIt"
                name="estType"
                validations={[required]}
                onChange={this.onRadioChange}
                value={this.props.propsFromIndex.estType}
              >
                <RadioButton value="clinic">Clinic</RadioButton>
                <RadioButton value="centre">Centre</RadioButton>
                <RadioButton value="hospital">Hospital</RadioButton>
              </RadioGroup>
              {this.props.propsFromIndex.estType === "" &&
                this.props.propsFromIndex.isSubmit && (
                  <span className="form-error is-visible">Required</span>
                )}
            </Col>
          </Row>
          <Row>
            <Col span={5}>
              <label>Attached with a laboratory?</label>
            </Col>
            <Col span={7}>
              <RadioGroup
                className="flexIt"
                name="labAttachment"
                validations={[required]}
                onChange={this.onRadioChange}
                value={this.props.propsFromIndex.labAttachment}
              >
                <RadioButton value="yes">Yes</RadioButton>
                <RadioButton value="no">No</RadioButton>
              </RadioGroup>
              {this.props.propsFromIndex.labAttachment === "" &&
                this.props.propsFromIndex.isSubmit && (
                  <span className="form-error is-visible">Required</span>
                )}
            </Col>
          </Row>
          <Row>
            <Col span={5}>
              <label>Attached with an imaging center?</label>
            </Col>
            <Col span={7}>
              <RadioGroup
                className="flexIt"
                validations={[required]}
                name="imagingAttachment"
                onChange={this.onRadioChange}
                value={this.props.propsFromIndex.imagingAttachment}
              >
                <RadioButton value="yes">Yes</RadioButton>
                <RadioButton value="no">No</RadioButton>
              </RadioGroup>
              {this.props.propsFromIndex.imagingAttachment === "" &&
                this.props.propsFromIndex.isSubmit && (
                  <span className="form-error is-visible">Required</span>
                )}
            </Col>
          </Row>
          <Row>
            <Col span={5}>
              <label>Attached with a blood bank?</label>
            </Col>
            <Col span={7}>
              <RadioGroup
                className="flexIt"
                name="bloodBankAttachment"
                validations={[required]}
                onChange={this.onRadioChange}
                value={this.props.propsFromIndex.bloodBankAttachment}
              >
                <RadioButton value="yes">Yes</RadioButton>
                <RadioButton value="no">No</RadioButton>
              </RadioGroup>
              {this.props.propsFromIndex.bloodBankAttachment === "" &&
                this.props.propsFromIndex.isSubmit && (
                  <span className="form-error is-visible">Required</span>
                )}
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}
export default First;
